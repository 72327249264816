import { useAuth0 } from "@auth0/auth0-react";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { FormProvider, Path, useForm } from "react-hook-form";
import invariant from "tiny-invariant";

import { getMaybeUser } from "@/common/services/user";
import { combineCallingCodeWithCountry } from "@/common/utils/phone";
import { CompanyDetailToForm, CompanyTo } from "@/generated/api/users";

import { InputField } from "../form/input-field/input-field";
import { PhoneNumber } from "../form/phone-number/phone-number";
import { useCountryCode } from "../user-settings/hooks/use-country-code";

type Fields = {
  companyName: string;
  companyNumber: string;
  vat: string;
  legalForm: CompanyDetailToForm;
  address: string;
  contact: {
    name: string;
    role: string;
    email: string;
    phone: {
      countryCode: string;
      phoneNumber: string;
    };
  };
};

const CompanyForm = ({
  initialValues,
  disabledFields,
  omitFields = [],
  onSubmit,
}: {
  initialValues: Fields;
  disabledFields: Array<Path<Fields>>;
  omitFields?: Array<Path<Fields>>;
  onSubmit: (data: Fields, e?: React.BaseSyntheticEvent) => void;
}) => {
  const { user: _user } = useAuth0();
  invariant(_user);
  const user = getMaybeUser(_user);
  const language = user.user_metadata?.can?.settings?.language ?? "en";
  const { phoneCountryCodes } = useCountryCode(language);
  const form = useForm<Fields>({
    defaultValues: initialValues,
  });

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        id="company-form"
        className="flex flex-col gap-y-6"
      >
        <InputField<Fields>
          disabled
          name="companyName"
          label={<Trans>Název společnosti</Trans>}
        />

        <InputField<Fields>
          disabled
          name="companyNumber"
          label={<Trans>IČO společnosti</Trans>}
        />

        <InputField<Fields>
          name="vat"
          disabled={disabledFields.includes("vat")}
          options={{
            required: t`Vyplňte prosím DIČ`,
          }}
          label={<Trans>DIČ</Trans>}
        />

        <InputField<Fields>
          name="address"
          disabled={disabledFields.includes("address")}
          options={{
            required: t`Vyplňte prosím adresu`,
          }}
          label={<Trans>Adresa</Trans>}
        />

        {omitFields.includes("legalForm") ? null : (
          <label className="group" htmlFor="legalForm">
            <Trans>Forma</Trans>
            <select
              disabled={disabledFields.includes("legalForm")}
              className="mt-2 block h-[calc(theme(spacing.14)+2px)] w-full rounded-lg font-bold group-has-[[role=alert]]:border-can-russet"
            >
              <option value={CompanyDetailToForm.JURIDICAL_PERSON}>
                <Trans>Právnická osoba</Trans>
              </option>
              <option value={CompanyDetailToForm.NATURAL_PERSON}>
                <Trans>Fyzická osoba</Trans>
              </option>
            </select>
          </label>
        )}

        <h2 className="font-bold text-can-forest-teal">
          <Trans>Kontaktní informace</Trans>
        </h2>

        {omitFields.includes("contact.name") ? null : (
          <InputField<Fields>
            name="contact.name"
            options={{
              required: t`Vyplňte prosím jméno kontaktní osoby`,
            }}
            label={<Trans>Kontaktní osoba</Trans>}
          />
        )}

        {omitFields.includes("contact.role") ? null : (
          <InputField<Fields>
            name="contact.role"
            options={{
              required: t`Vyplňte prosím pozici`,
            }}
            label={<Trans context="job role">Pozice</Trans>}
          />
        )}

        <InputField<Fields>
          name="contact.email"
          type="email"
          options={{
            required: t`Vyplňte prosím e-mail`,
          }}
          label={<Trans>E-mailová adresa</Trans>}
        />

        <PhoneNumber
          name="contact.phone"
          callingCodes={phoneCountryCodes.data}
        />
      </form>
    </FormProvider>
  );
};

const getInitialValues = (
  company: Omit<CompanyTo, "planExpiresAt" | "subscriptions">,
) => ({
  companyName: company.name,
  companyNumber: company.companyNumber,
  vat: company.detail?.vat ?? "",
  address: company.detail?.address ?? "",
  legalForm: company.detail?.form ?? CompanyDetailToForm.JURIDICAL_PERSON,
  contact: {
    name: company.detail?.contactName ?? "",
    role: company.detail?.contactPosition ?? "",
    email: company.detail?.contactEmail ?? "",
    phone: {
      countryCode: company.detail?.contactPhone
        ? combineCallingCodeWithCountry({
            phoneCountryCode: company.detail?.contactPhone?.countryCode,
            id: company.country,
          })
        : company.country,
      phoneNumber: company.detail?.contactPhone?.phoneNumber ?? "",
    },
  },
});

export { CompanyForm, getInitialValues };
export type { Fields };

import AngleLeft from "@icons/angle-left.svg?react";
import { msg } from "@lingui/core/macro";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/react/macro";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import invariant from "tiny-invariant";
import { z } from "zod";

import {
  canSignCheckFn,
  useGuard,
} from "@/common/acl/action-guard/action-guard";
import { Button } from "@/common/components/button/button";
import { Metadata } from "@/common/components/metadata/metadata";
import { Fields, OrderForm } from "@/common/components/order-form/order-form";
import { PropertiesList } from "@/common/components/properties-list/properties-list";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { Spacer } from "@/common/components/spacer/spacer";
import { useCurrency } from "@/common/hooks/use-currency";
import { entitySchema } from "@/common/schema/entity";

import { FullscreenModalLayout } from "../layout/layout.fullscreen";
import { handleFormError } from "../shared/api/error-handler";
import { path as failedPath } from "../shared/routes/failed/failed";
import { path as successPath } from "../shared/routes/success/success";
import { useAddOrderMutation } from "./trades.api";

const path = "order";
const TradesDetailOrder = () => {
  const { _ } = useLingui();
  const { offerId } = useParams();
  const [searchParams] = useSearchParams();
  invariant(offerId);
  const [, currency] = useCurrency();
  const context = useTradesDetailOrder();
  const navigate = useNavigate();
  const addOrder = useAddOrderMutation();
  const handleSubmit = (data: Fields) => {
    addOrder.mutate(
      {
        lowPrice: data.lowPricePerTon,
        price: data.pricePerTon,
        offerId,
        customTransport: data.customTransport,
        currency,
        note: data.note,
        amount: data.tons,
        quarterId: data.quarter,
      },
      {
        onError: (e) => {
          const errors = handleFormError(e);
          navigate("../../" + failedPath + "?" + searchParams.toString(), {
            state: { errors },
          });
        },
        onSuccess: (mutation) => {
          navigate("../../" + successPath + "/order/" + mutation.addOrder?.id);
        },
      },
    );
  };
  const guardedSubmit = useGuard({
    action: handleSubmit,
    checkFn: canSignCheckFn,
  });

  return (
    <FullscreenModalLayout
      headerButton={
        <SearchParamsLink to=".." replace>
          <AngleLeft />
        </SearchParamsLink>
      }
    >
      <Metadata title={_(msg`Nastavit příkaz k prodeji`)} />
      <FullscreenModalLayout.Title>
        <Trans>Nastavit příkaz k prodeji</Trans>
      </FullscreenModalLayout.Title>
      <PropertiesList
        properties={[
          {
            label: <Trans>Komodita</Trans>,
            value: context.crop.name,
          },
          {
            label: <Trans>Farma</Trans>,
            value: context.company.name,
          },
          {
            label: <Trans>Sklad</Trans>,
            value: context.storage.name,
          },
        ]}
      />
      <Spacer className="h-6" />
      <OrderForm onSubmit={guardedSubmit} initialValues={{}}>
        <OrderForm.QuarterField />
        <OrderForm.LowPriceField currency={currency} />
        <OrderForm.PriceField currency={currency} />
        <OrderForm.TraderField />
        <OrderForm.AmountField />
        <OrderForm.CustomTransportField />
        <OrderForm.NoteField />
      </OrderForm>
      <Button.Container>
        <Button
          type="submit"
          disabled={addOrder.status === "pending"}
          form={OrderForm.formId}
          variant="primary"
        >
          <Trans>Nastavit příkaz</Trans>
        </Button>
      </Button.Container>
    </FullscreenModalLayout>
  );
};

const contextSchema = z.object({
  crop: entitySchema,
  company: entitySchema,
  storage: entitySchema,
  offer: z.object({
    amount: z.number(),
  }),
});

const useTradesDetailOrder = () => {
  const context = useOutletContext();
  return contextSchema.parse(context);
};

export { path, TradesDetailOrder };

import {
  getFilterByDateRange,
  getFilterParams,
  initialFilter,
} from "./quick-filters.utils";

const QuickFilters = ({
  activeFilter,
  filters,
  onClick,
}: {
  filters: readonly { label: string }[];
  activeFilter?: { label: string } | { label: string }[];
  onClick: (index: number) => void;
}) => {
  const isCurrent = (filter: { label: string }) =>
    (Array.isArray(activeFilter) ? activeFilter : [activeFilter]).find(
      (f) => f?.label === filter.label,
    );

  return (
    <ul role="navigation" className="inline-flex flex-shrink-0 gap-1.5">
      {filters.map((filter, i) => (
        <li
          className="inline-block flex-shrink-0"
          aria-current={isCurrent(filter) ? "page" : false}
          key={filter.label}
        >
          <button
            onClick={() => onClick(i)}
            className={`self-center rounded border border-can-forest-teal px-1.5 py-0.5 text-xs text-can-forest-teal ${
              isCurrent(filter)
                ? "border-transparent bg-can-gradient-to-r-80 from-can-tranquil-azure to-can-mystic-aqua font-bold text-white"
                : ""
            }`}
          >
            {filter.label}
          </button>
        </li>
      ))}
    </ul>
  );
};

export {
  getFilterByDateRange,
  getFilterParams,
  initialFilter as initialDateFilter,
  QuickFilters,
};

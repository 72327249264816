import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { FormProvider, useForm } from "react-hook-form";

import { resetForm } from "@/api/form-reset";
import { Button } from "@/common/components/button/button";
import { InputField } from "@/common/components/form/input-field/input-field";
import { Spacer } from "@/common/components/spacer/spacer";
import { SubscriptionToSource } from "@/generated/api/users";
import { companyPlansQuery } from "@/routes/shared/api/company.api";

import {
  companyQuery,
  useUpdateCompanySubscriptionPlanMutation,
} from "../company.api";
import { useCompanyId } from "../use-company-id";

type SubscriptionFields = {
  plan: number;
  expiresAt: DateTime;
};

const SubscriptionForm = () => {
  const queryClient = useQueryClient();
  const companyId = useCompanyId();
  const company = useSuspenseQuery(companyQuery({ id: parseInt(companyId) }));
  const manualSubscription = company.data.subscriptions
    .filter((s) => s.source === SubscriptionToSource.MANUAL)
    .at(-1);
  const plans = useSuspenseQuery(companyPlansQuery());
  const form = useForm<SubscriptionFields>({
    defaultValues: {
      expiresAt: manualSubscription?.expiresAt?.toISODate() ?? undefined,
      plan: manualSubscription?.plan.id,
    },
  });
  const updateCompanySubscriptionPlan =
    useUpdateCompanySubscriptionPlanMutation();

  const handleSubmit = (data: SubscriptionFields) => {
    updateCompanySubscriptionPlan.mutate(
      {
        companyId: parseInt(companyId),
        plan: data.plan,
        expiresAt: data.expiresAt.toISODate()!,
      },
      {
        onError: resetForm(form),
        onSuccess: () => {
          void queryClient.invalidateQueries();
        },
      },
    );
  };

  return (
    <details>
      <summary>
        <h2 className="inline text-lg font-bold">
          <Trans>Nastavit předplatné</Trans>
        </h2>
      </summary>
      <Spacer className="h-6" />
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="flex flex-col gap-y-4"
        >
          <div className="flex gap-x-2">
            <label
              className="flex shrink-0 basis-1/2 flex-col"
              htmlFor="phoneNumber"
            >
              <Trans>Předplatné</Trans>
              <select
                {...form.register("plan")}
                className="mt-2 h-[calc(theme(spacing.14)+2px)] rounded-lg font-bold group-has-[[role=alert]]:border-can-russet"
              >
                {plans.data.map((plan) => (
                  <option key={plan.id} value={plan.id}>
                    {plan.name}
                  </option>
                ))}
              </select>
            </label>
            <InputField<SubscriptionFields>
              label={<Trans>Platné do</Trans>}
              name="expiresAt"
              type="date"
              options={{
                setValueAs: (v) => DateTime.fromISO(v),
                required: t`Prosím vyplňte platnost předplatného`,
                validate: (date) => {
                  if (!DateTime.isDateTime(date)) {
                    return t`Zadané datum není platné`;
                  }

                  return (
                    date > DateTime.now() || t`Vyplňte prosím budoucí datum`
                  );
                },
              }}
            />
          </div>
          <Button
            disabled={updateCompanySubscriptionPlan.status === "pending"}
            className="w-full"
            variant="primary"
          >
            <Trans>Uložit</Trans>
          </Button>
        </form>
      </FormProvider>
    </details>
  );
};

export { SubscriptionForm };

import { queryOptions } from "@tanstack/react-query";
import { z } from "zod";

import { graphqlClient } from "@/api/graphql";
import { graphql } from "@/generated/digitalnisklady.cz";

// eslint-disable-next-line lingui/no-unlocalized-strings
const companiesDocument = graphql(`
  query Companies {
    user {
      companies {
        edges {
          node {
            id
            name
            vatNumber
          }
        }
      }
    }
  }
`);

type Companies = z.infer<typeof companiesSchema>;
const companiesSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
  }),
);

const companiesQuery = () =>
  queryOptions({
    queryKey: ["companies"],
    staleTime: Infinity,
    queryFn: () => graphqlClient.request(companiesDocument),
    select: (data) => {
      const result = data?.user?.companies?.edges?.map((company) => ({
        id: company?.node?.id,
        name: company?.node?.name,
      }));

      return companiesSchema.parse(result);
    },
  });

export { companiesQuery };
export type { Companies };

import angleSmallDownSvg from "@icons/angle-small-down.svg?url";
import CheckCircleGreen from "@icons/check-circle-green.svg?react";
import { t } from "@lingui/core/macro";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/react/macro";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import invariant from "tiny-invariant";

import { ActionGuard, useGuard } from "@/common/acl/action-guard/action-guard";
import { Button } from "@/common/components/button/button";
import { PageTitle } from "@/common/components/page-title/page-title";
import { Spacer } from "@/common/components/spacer/spacer";
import { Spinner } from "@/common/components/spinner/spinner";
import { useRequiredFirstCompany } from "@/common/hooks/use-first-company";
import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";
import { currencyFormatter, getPrice } from "@/common/services/formatter";
import { getLocalizedPeriods } from "@/common/utils/period";
import { getCompanyPlans, PlanPeriodTo, PlanTo } from "@/generated/api/users";
import { getTranslation } from "@/generated/plan-feature-i18n";
import { TwoColumnLayout } from "@/routes/layout/layout";

const Plans = ({ children }: React.PropsWithChildren) => {
  const company = useRequiredFirstCompany();
  const plans = useSuspenseQuery(plansQuery());

  return (
    <TwoColumnLayout
      right={false}
      left={{
        header: (
          <>
            {children}
            <div className="mx-auto">
              <PageTitle>
                <Trans>Odemkněte si všechny funkce</Trans>
              </PageTitle>
            </div>
          </>
        ),
        content: (
          <div className="flex w-full max-w-full flex-col items-center gap-y-6 @container">
            {company.plan.type === "BASIC" ? (
              <p>
                <Trans>
                  Využíváte základní předplatné {company.plan.type}, proto jsou
                  pro vás některé funkce nedostupné. Vyberte si vyšší balíček
                  služeb.
                </Trans>
              </p>
            ) : null}
            <div className="mx-auto grid w-full max-w-[1038px] grid-cols-1 justify-items-center gap-6 gap-y-6 @[1038px]:grid-cols-3">
              {plans.data.map((plan) => (
                <Plan
                  key={plan.id}
                  plan={plan}
                  active={plan.id === company.plan.planId}
                />
              ))}
            </div>
            <Spacer className="h-6" />
          </div>
        ),
      }}
    ></TwoColumnLayout>
  );
};

const Plan = ({ active, plan }: { active: boolean; plan: PlanTo }) => {
  const i18n = useLingui();
  return (
    <section
      className={`relative flex w-full flex-col items-center gap-y-2 self-start rounded-2xl p-6 shadow-can-light-box @sm:min-w-[330px] @sm:max-w-[342px] ${active ? "bg-can-silver-gray" : ""}`}
    >
      <h2 className="text-center text-xl font-bold uppercase text-can-midnight-steel">
        {plan.name}
      </h2>
      <p>
        {active ? (
          <Trans>Toto je váš aktuální balíček služeb.</Trans>
        ) : (
          <Trans>V balíčku najdete tyto služby:</Trans>
        )}
      </p>
      <ul className="flex w-full flex-col gap-y-6">
        {plan.features.map((feature) => {
          const featureDescriptionId = `${feature.feature.type.toLowerCase()}_${feature.mode.toLowerCase()}_description`;
          const featureTitleId = `${feature.feature.type.toLowerCase()}_${feature.mode.toLowerCase()}_title`;
          return (
            <li key={feature.feature.type} className="flex gap-x-4">
              <span className="inline-block flex-shrink-0 self-start rounded-full bg-[#ebebeb] p-4">
                <img className="h-6 w-6" src={feature.feature.icon} />
              </span>
              <div className="flex flex-col gap-1">
                <h3 className="font-bold text-can-midnight-steel">
                  {i18n._(getTranslation(featureTitleId))}
                </h3>
                <p className="leading-4">
                  <small>{i18n._(getTranslation(featureDescriptionId))}</small>
                  <br />
                </p>
              </div>
            </li>
          );
        })}
      </ul>
      {plan.periods.length > 0 ? (
        <Pricing plan={plan.type} periods={plan.periods} />
      ) : (
        <FreePricing />
      )}
      {active ? <CheckCircleGreen className="absolute right-4 top-4" /> : null}
    </section>
  );
};

const FreePricing = () => (
  <span className="bg-can-can-slate-blue-gray mt-6 inline-block w-full rounded-2xl bg-[#ebebeb] p-3 text-center">
    <Trans>Měsíční předplatné zdarma</Trans>
  </span>
);

const Pricing = ({
  plan,
  periods,
}: {
  plan: string;
  periods: PlanPeriodTo[];
}) => {
  const form = useForm<{ period: PlanPeriodTo["type"] }>({
    defaultValues: {
      period: "YEARLY",
    },
  });
  const translations = getLocalizedPeriods();
  const activePeriod = form.watch("period");
  const activePrice = periods.find((period) => period.type === activePeriod);
  invariant(activePrice);
  const formattedPrice = getFormattedPrice(
    getPrice(activePrice.price, activePrice.currency.precision),
    activePrice.currency.type,
  );
  const { actions } = useSidebar();

  const handlePeriodClick = () => {
    const periodId = activePrice.id;
    invariant(periodId);
    actions.openSubscriptionOverview({
      price: formattedPrice,
      plan,
      period: activePeriod,
      periodId,
    });
  };

  const handleGuardedPeriodClick = useGuard({
    action: handlePeriodClick,
  });

  return (
    <div className="mt-6 flex w-full flex-col">
      <h4>
        <Trans>Období</Trans>
      </h4>
      <Spacer className="h-4" />
      <select
        style={{ backgroundImage: `url(${angleSmallDownSvg})` }}
        defaultValue=""
        className="h-[calc(theme(spacing.14)+2px)] w-full rounded-lg p-4 font-bold text-can-midnight-steel group-has-[[role=alert]]:border-can-russet"
        {...form.register("period", {
          required: t`Vyberte prosím období`,
        })}
      >
        {periods.map((period) => (
          <option key={period.type} value={period.type}>
            {translations[period.type]}
          </option>
        ))}
      </select>
      <Spacer className="h-6" />
      <Button onClick={handleGuardedPeriodClick} variant="primary">
        <Trans>
          Přejít na {plan} za {formattedPrice}
        </Trans>
      </Button>
      <Spacer className="h-2" />
      <small className="text-center text-xs">
        <Trans>Cena je uvedena bez DPH.</Trans>
      </small>
    </div>
  );
};

const getFormattedPrice = (price: number, currency: string) => {
  return currencyFormatter(currency).format(price);
};

const plansQuery = () =>
  queryOptions({
    queryKey: ["plans"],
    queryFn: ({ signal }) => getCompanyPlans({ inPlan: true }, { signal }),
    staleTime: Infinity,
  });

const LockedScreen = ({ children }: React.PropsWithChildren) => (
  <ActionGuard
    guard={{
      title: <Trans>Akci nelze dokončit</Trans>,
      content: <Trans>Nemáte dostatečné oprávnění.</Trans>,
      action: <Trans>Zavřít</Trans>,
    }}
    grant="PAYMENT_CREATE"
  >
    <React.Suspense
      fallback={
        <div className="mx-auto pt-14">
          <Spinner />
        </div>
      }
    >
      <Plans>{children}</Plans>
    </React.Suspense>
  </ActionGuard>
);

export { LockedScreen };

import SubscriptionSvg from "@icons/subscription.svg?react";
import UserSvg from "@icons/user.svg?react";
import { Trans } from "@lingui/react/macro";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Link } from "react-router-dom";

import { useFirstCompany } from "@/common/hooks/use-first-company";
import { companyPlansQuery } from "@/routes/shared/api/company.api";

import { secondary } from "../../button/button";
import { Failed } from "../../info/info";
import { Spinner } from "../../spinner/spinner";

type ItemKey = (typeof items)[number]["id"];
type ItemFilter = Record<ItemKey, boolean>;
type Props = {
  renderItem: Record<
    ItemKey,
    (item: (typeof items)[number]) => React.ReactNode
  >;
};

const items = [
  {
    id: "profile",
    label: <Trans>Profil</Trans>,
    icon: <UserSvg />,
  },
  {
    id: "subscription",
    label: <Trans>Předplatné</Trans>,
    icon: <SubscriptionSvg />,
  },
] as const;

const UserMenu = ({ renderItem }: Props) => {
  const company = useFirstCompany();
  const hasMultiplePlans = useQuery({
    ...hasMultiplePlansQuery(),
    enabled: !!company,
  });
  const hasPlans = useFlag("packages");
  const itemFilter: ItemFilter = {
    profile: true,
    subscription: hasPlans && (hasMultiplePlans.data ?? false),
  };
  return (
    <>
      {items
        .filter((item) => itemFilter[item.id])
        .map((item) => (
          <li key={item.id}>{renderItem[item.id](item)}</li>
        ))}

      <li className="mx-auto mt-4 flex w-[80%]">
        <Link className={`${secondary} basis-full`} to="/logout">
          <Trans>Odhlásit se</Trans>
        </Link>
      </li>
    </>
  );
};

const UserMenuTree = (props: Props) => (
  <ErrorBoundary fallbackRender={({ error }) => <Failed error={error} />}>
    <Suspense fallback={<Spinner className="mt-4" withHint={false} />}>
      <UserMenu {...props} />
    </Suspense>
  </ErrorBoundary>
);

const Desktop = ({ children }: React.PropsWithChildren) => (
  <ul className="mt-10 flex flex-col rounded-2xl border bg-white pb-8 font-bold text-can-forest-teal shadow-can-light-box">
    {children}
  </ul>
);

const Mobile = ({ children }: React.PropsWithChildren) => (
  <ul className="flex flex-col gap-8">{children}</ul>
);

UserMenuTree.Desktop = Desktop;
UserMenuTree.Mobile = Mobile;

const hasMultiplePlansQuery = () =>
  queryOptions({
    ...companyPlansQuery(),
    queryKey: ["company", "multiple", "plans"],
    select: (plans) => plans.length > 1,
  });

export { UserMenuTree as UserMenu };

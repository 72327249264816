import React from "react";

import { usePrevious } from "./use-previous";

const useScrollRestoration = (active: boolean) => {
  const previouslyActive = usePrevious(active);
  const linkRef = React.useRef<HTMLAnchorElement>();
  const isScrollingRef = React.useRef(false);
  const clickTimeoutRef = React.useRef<NodeJS.Timeout>();

  const scrollIntoView = () => {
    if (!linkRef.current) return;

    if (
      "scrollIntoViewIfNeeded" in linkRef.current &&
      typeof linkRef.current.scrollIntoViewIfNeeded === "function"
    ) {
      linkRef.current.scrollIntoViewIfNeeded();
      return;
    }

    if (
      "scrollIntoView" in linkRef.current &&
      typeof linkRef.current.scrollIntoView === "function"
    ) {
      linkRef.current.scrollIntoView({ block: "nearest", inline: "nearest" });
    }
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLElement>) => {
    const clickedLink = (e.target as HTMLElement).closest("a");
    if (!clickedLink) return;

    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
    }

    linkRef.current = clickedLink;
    isScrollingRef.current = true;

    // Set a flag to ignore scroll events for a short period after click
    clickTimeoutRef.current = setTimeout(() => {
      isScrollingRef.current = false;
    }, 100);

    queueMicrotask(() => {
      if (linkRef.current) {
        scrollIntoView();
      }
    });
  };

  const handleScroll = () => {
    // Only handle scroll if it's not triggered by our click handler
    if (isScrollingRef.current) {
      return;
    }

    queueMicrotask(() => {
      linkRef.current = undefined;
    });
  };

  const focusClickedElement = () => {
    if (!linkRef.current || active === previouslyActive) return;

    queueMicrotask(() => {
      if (linkRef.current) {
        scrollIntoView();
      }
    });
  };

  React.useEffect(() => {
    return () => {
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
    };
  }, []);

  return { handleLinkClick, focusClickedElement, handleScroll };
};

export { useScrollRestoration };

import BooksSvg from "@icons/books.svg?react";
import ChartSvg from "@icons/chart.svg?react";
import GarageOpenSvg from "@icons/garage-open.svg?react";
import MagnifyChartSvg from "@icons/magnify-chart.svg?react";
import NewspaperSvg from "@icons/newspaper.svg?react";
import SendMoneySvg from "@icons/send-money.svg?react";
import UsdCircleSvg from "@icons/usd-circle.svg?react";
import { t } from "@lingui/core/macro";
import { NavLink } from "react-router-dom";

import { Features } from "@/common/providers/feature-provider";
import { PlanSchema } from "@/common/services/user";
import { MenuItemFlow } from "@/domain/plan/nav-bar/nav-bar.flow";
import { FeatureToType } from "@/generated/api/users";
import { path as purchasePricesPath } from "@/routes/purchase-prices/purchase-prices";
import { path as reportsPath } from "@/routes/reports/reports";
import { getAbsolutePath } from "@/routes/routes.utils";
import { path as stockExchangePath } from "@/routes/stock-exchange/stock-exchange";
import { path as storagesPath } from "@/routes/storages/storages";
import { path as tradesPath } from "@/routes/trades/trades";

type Link = {
  className?: string | ((args: { isActive: boolean }) => string);
  to: string;
  icon: React.ReactNode;
  label: string;
};

type LinkWithFeature = Link & { feature: FeatureToType };

const NavBar = ({
  plan,
  renderItem,
}: {
  plan: Omit<PlanSchema, "planId"> & {
    features: Features;
    planId: PlanSchema["planId"] | null;
  };
  renderItem?: (
    link: Link,
    wrapper: Exclude<
      ReturnType<InstanceType<typeof MenuItemFlow>["getWrapper"]>,
      null
    >,
  ) => React.ReactNode;
}) => {
  const links: LinkWithFeature[] = [
    {
      className: getClassName,
      feature: FeatureToType.PURCHASE_PRICES,
      label: t`Moje výkupní ceny`,
      to: getAbsolutePath(purchasePricesPath),
      icon: <MagnifyChartSvg />,
    },
    {
      className: getClassName,
      feature: FeatureToType.QUICK_TRADING,
      label: t`Rychlý prodej komodit`,
      to: getAbsolutePath("quick-trading"),
      icon: <SendMoneySvg />,
    },
    {
      className: getClassName,
      feature: FeatureToType.STOCK_EXCHANGE,
      label: t`Burzovní informace`,
      to: getAbsolutePath(stockExchangePath),
      icon: <ChartSvg />,
    },
    {
      className: getClassName,
      feature: FeatureToType.MARKET_NEWS,
      label: t`Analýza trhu`,
      to: getAbsolutePath(reportsPath),
      icon: <NewspaperSvg />,
    },
    {
      className: getClassName,
      feature: FeatureToType.TRADING,
      label: t`Prodej komodit`,
      to: getAbsolutePath(tradesPath),
      icon: <UsdCircleSvg />,
    },
    {
      className: getClassName,
      feature: FeatureToType.STORAGES,
      label: t`Sklady`,
      to: getAbsolutePath(storagesPath),
      icon: <GarageOpenSvg />,
    },
    {
      className: getClassName,
      label: t`Transakce a příkazy`,
      feature: FeatureToType.TRANSACTIONS_ORDERS,
      to: getAbsolutePath("transactions-and-orders"),
      icon: <BooksSvg />,
    },
  ];

  return (
    <nav className="flex w-full flex-grow flex-col items-center gap-3 font-bold text-white shadow-can-light-box">
      {links.map((link) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { feature, ...restOfLink } = link;
        const flow = new MenuItemFlow(link, plan);
        const Wrapper = flow.getWrapper();

        if (!Wrapper) {
          return null;
        }

        return typeof renderItem === "function" ? (
          renderItem(restOfLink, Wrapper)
        ) : (
          <NavLink
            viewTransition
            title={link.label}
            key={link.to}
            {...restOfLink}
          >
            <Wrapper>{link.icon}</Wrapper>
          </NavLink>
        );
      })}
    </nav>
  );
};

// eslint-disable-next-line lingui/no-unlocalized-strings
const active = "[&_path]:fill-white bg-[#399E82] text-white";
// eslint-disable-next-line lingui/no-unlocalized-strings
const inactive = "[&_path]:fill-[#85C4B3] text-[#85C4B3]";
const getClassName = ({ isActive }: { isActive: boolean }) =>
  // eslint-disable-next-line lingui/no-unlocalized-strings
  `${isActive ? active : inactive} flex w-full justify-center py-3 relative`;

export { active, getClassName, NavBar };
export type { LinkWithFeature };

import { t } from "@lingui/core/macro";

/**
 * Represents CORS kind of errors
 */
class NetworkError extends Error {
  constructor(message = t`Chyba spojení`) {
    super(message);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.name = "NetworkError";
  }

  static getName() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return "NetworkError";
  }
}

class ClientError extends Error {
  status?: number;

  constructor(message = "", options?: { status?: number }) {
    super(message);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.name = "ClientError";
    this.status = options?.status;
  }

  static getName() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return "ClientError";
  }

  public getStatus() {
    return this.status;
  }
}

class ServerError extends Error {
  constructor(message = "") {
    super(message);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.name = "ServerError";
  }

  static getName() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return "ServerError";
  }
}

class UnauthorizedError extends Error {
  constructor(message = t`Pouze pro autentizované uživatele`) {
    super(message);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.name = "UnauthorizedError";
  }

  static getName() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return "UnauthorizedError";
  }
}

class ForbiddenError extends Error {
  constructor(message = t`Nedostatečné oprávnění`) {
    super(message);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.name = "ForbiddenError";
  }

  static getName() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return "ForbiddenError";
  }
}

class NotFoundError extends Error {
  constructor(message = t`Bohužel, nic jsme nenašli`) {
    super(message);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.name = "NotFoundError";
  }

  static getName() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return "NotFoundError";
  }
}

class TooManyCompaniesError extends Error {
  constructor(message = t`Uživatel přiřazen k více společnostem`) {
    super(message);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.name = "TooManyCompaniesError";
  }

  static getName() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return "TooManyCompaniesError";
  }
}

const isClientError = (
  error: unknown,
): error is { message: string; status: number } =>
  typeof error === "object" &&
  error !== null &&
  "message" in error &&
  "status" in error;

export {
  ClientError,
  ForbiddenError,
  isClientError,
  NetworkError,
  NotFoundError,
  ServerError,
  TooManyCompaniesError,
  UnauthorizedError,
};

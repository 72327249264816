import { User } from "@auth0/auth0-react";
import { z } from "zod";

type UserState = "complete" | "incomplete";

const getUserState = (user: User): UserState => {
  try {
    getUser(user);
    return "complete";
  } catch (e) {
    return "incomplete";
  }
};

const approvalStatus = z.union([
  z.literal("WAITING"),
  z.literal("APPROVED"),
  z.literal("BLOCKED"),
]);

const role = z.union([z.literal("USER"), z.literal("STATUTORY")]);
const grants = [
  "COMPANY_DETAIL_EDIT",
  "CONTRACT_SIGN",
  "PAYMENT_CREATE",
] as const;

const commonSchema = z.object({
  phone: z.object({
    phoneNumber: z.string(),
    countryCode: z.string(),
  }),
});
const CANSchema = z.object({
  companies: z
    .array(
      z.object({
        companyNumber: z.string(),
        countryCode: z.string(),
      }),
    )
    .nullish(),
  settings: z
    .object({
      language: z.string(),
      news: z
        .object({
          language: z.string(),
        })
        .or(z.undefined()),
    })
    .or(z.undefined()),
});

const userMetadataSchema = z.object({
  common: commonSchema,
  can: CANSchema,
});

const planTypeSchema = z.union([
  z.literal("PLUS"),
  z.literal("BASIC"),
  z.literal("PREMIUM"),
]);

const planSchema = z.object({
  planId: z.number(),
  type: planTypeSchema,
});

const appMetadataSchema = z
  .object({
    events: z.object({
      // We effectively use this to tell whether the user has been reset. If they have been reset, `registeredAt` field has been reset as well
      // and thus the zod check will throw.
      registeredAt: z.number(),
    }),
    companies: z
      .array(
        z.object({
          companyId: z.number(),
          companyVat: z
            .string()
            .nullable()
            .transform((v) => v ?? undefined),
          countryCode: z
            .string()
            .nullable()
            .transform((v) => v ?? undefined),
          companyNumber: z
            .string()
            .nullable()
            .transform((v) => v ?? undefined),
          companyContactEmail: z
            .string()
            .nullable()
            .transform((v) => v ?? undefined),
          approvalState: approvalStatus,
          plan: planSchema,
          grants: z.array(z.enum(grants)),
          role,
          users: z.array(
            z.object({
              approvalState: approvalStatus,
              grants: z.array(z.enum(grants)),
              role,
              userEmail: z.string().email(),
              userId: z.string(),
            }),
          ),
        }),
      )
      .nullish(),
  })
  .nullish();

const userIdentitySchema = z.object({
  name: z.string(),
  email: z.string(),
});

const userSchema = z
  .object({
    user_metadata: userMetadataSchema,
    "can/app_metadata": appMetadataSchema,
  })
  .merge(userIdentitySchema);

const incompleteUserSchema = userSchema.deepPartial().merge(userIdentitySchema);

type UserSchema = z.infer<typeof userSchema>;
type IncompleteUserSchema = z.infer<typeof incompleteUserSchema>;
type ApprovalStatus = z.infer<typeof approvalStatus>;
type Role = z.infer<typeof role>;
type PlanSchema = z.infer<typeof planSchema>;
type Grant = (typeof grants)[number];
type Company = Exclude<
  Exclude<UserSchema["can/app_metadata"], undefined | null>["companies"],
  undefined | null
>[number];

const getUser = (user: User): UserSchema => userSchema.parse(user);

const getMaybeUser = (user: User) => incompleteUserSchema.parse(user);

const isBlocked = (
  user: z.infer<typeof userSchema> | z.infer<typeof incompleteUserSchema>,
) => {
  const metadata = user["can/app_metadata"];
  if (!metadata?.companies) {
    return false;
  }

  if (metadata.companies.length === 0) {
    return false;
  }
  return metadata.companies.every(
    (company) => company.approvalState === "BLOCKED",
  );
};

export { getMaybeUser, getUser, getUserState, isBlocked, planTypeSchema };
export type {
  ApprovalStatus,
  Company,
  Grant,
  IncompleteUserSchema,
  PlanSchema,
  Role,
  UserSchema,
};

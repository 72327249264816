/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { isClientError, isNotFoundError } from "@/api/errors";

import { AppModeSchema, getEnvironmentVariable } from "./env";

class Logger {
  constructor(private appMode: AppModeSchema) {
    this.setUser = this.setUser.bind(this);
    this.log = this.log.bind(this);
    this.error = this.error.bind(this);
    this.isRemoteLoggingEnabled = this.isRemoteLoggingEnabled.bind(this);
    this.isTestRun = this.isTestRun.bind(this);

    if (this.isRemoteLoggingEnabled()) {
      Sentry.init({
        beforeSend(e, hint) {
          if (
            hint.originalException &&
            isNotFoundError(hint.originalException)
          ) {
            return null;
          }

          if (
            hint.originalException &&
            isClientError(hint.originalException) &&
            hint.originalException.status === 409 &&
            (hint.originalException.message as string).match(
              /token for user already exists/i,
            )
          ) {
            return null;
          }

          return e;
        },
        dsn: "https://9ca524635148db6f53ce6c67ff523e31@o82643.ingest.us.sentry.io/4507186468290560",
        environment: this.appMode,
        release: getEnvironmentVariable("RELEASE_ID"),
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
          Sentry.replayIntegration(),
          Sentry.httpClientIntegration(),
        ],
        sendDefaultPii: true,
        tracesSampleRate: 0.25,
        tracePropagationTargets: [
          "localhost",
          /cleverfarm\.cz/, // matches nightly and staging API
          /cleveranalytics\.ag/, // matches production API
          /^\//, // matches anything on the same URL
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  }

  public setUser(user: Sentry.User | null) {
    if (!this.isRemoteLoggingEnabled()) {
      return;
    }
    Sentry.setUser(user);
  }

  public log(...args: unknown[]) {
    if (this.isTestRun()) {
      return;
    }
    console.log(...args);
  }

  public error(e: Error | unknown | undefined, ...args: unknown[]) {
    if (this.isTestRun()) {
      return;
    }
    console.error(e, ...args);

    if (this.isRemoteLoggingEnabled()) {
      return Sentry.captureException(e);
    }
  }

  private isRemoteLoggingEnabled() {
    return this.appMode !== "local";
  }

  private isTestRun() {
    return import.meta.env.MODE === "test";
  }
}

const logger = new Logger(getEnvironmentVariable("APP_MODE"));

export { logger };

import angleSmallDownSvg from "@icons/angle-small-down.svg";
import bearishSvg from "@icons/bearish.svg";
import bullishSvg from "@icons/bullish.svg";
import Cross from "@icons/cross.svg?react";
import DiamondSvgSrc from "@icons/diamond-cta.svg?url";
import equalSvg from "@icons/equal.svg";
import newspaperSvg from "@icons/newspaper.svg";
import { setupI18n } from "@lingui/core";
import { msg } from "@lingui/core/macro";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/react/macro";
import { useSuspenseQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import React from "react";
import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import BlackSilo from "@/assets/black-silo.svg?react";
import { Button } from "@/common/components/button/button";
import { Metadata } from "@/common/components/metadata/metadata";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { Spacer } from "@/common/components/spacer/spacer";
import { useIsMobile } from "@/common/hooks/use-media-query";
import { I18nProvider } from "@/common/providers/i18n-provider";
import { Mode, ReportsFlow } from "@/domain/plan/reports/reports.flow";
import { ReportCommodityToRecommendation } from "@/generated/api/news";
import { path as reportsPath } from "@/routes/reports/reports";

import { FullscreenModalLayout } from "../layout/layout.fullscreen";
import { useEnforcePlans } from "../reports/reports.hooks";
import { getAbsolutePath } from "../routes.utils";
import { reportQuery } from "./report.api";

const myI18n = setupI18n();

const path = ":reportId";
const Report = ({ mode }: { mode: Mode }) => {
  const flow = React.useRef(new ReportsFlow());
  flow.current.setStrategy(mode);
  const isMobile = useIsMobile();
  const { reportId } = useParams();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(reportId, "Report id is missing.");
  const report = useSuspenseQuery({
    ...reportQuery(parseInt(reportId)),
    select: (data) => {
      const isMasked = flow.current.getMasked({
        isStub: data.commodities.length === 0,
      });
      const dummy = isMasked ? flow.current.getDummyReport() : {};
      return { ...data, ...dummy, isMasked };
    },
  });
  const { enforcePlans } = useEnforcePlans();

  const _dateFrom = DateTime.fromISO(report.data.dateFrom).toJSDate();
  const _dateTo = DateTime.fromISO(report.data.dateTo).toJSDate();
  const dateRangeFormatter = new Intl.DateTimeFormat();

  if (enforcePlans) {
    return null;
  }

  return (
    <Container>
      <Metadata title={report.data.headline} />
      <h1 className="text-2xl font-black text-can-forest-teal">
        {report.data.headline}
      </h1>
      <Spacer className="h-6" />
      {report.data.image ? (
        <img
          src={report.data.image.url}
          className="rounded-lg border border-can-silver-cloud"
        />
      ) : null}
      <Spacer className="h-6" />
      <span className="text-can-midnight-steel">
        {dateRangeFormatter.formatRange(_dateFrom, _dateTo)}
      </span>
      <Spacer className="h-4" />
      <p className="text-can-midnight-steel">{report.data.summary}</p>
      {report.data.commodities.map((commodity) => (
        <React.Fragment key={commodity.commodity.id}>
          <Spacer className="h-4" />
          <details
            open={!isMobile}
            className={`group text-can-stormy-sky ${report.data.isMasked ? "blur-sm" : ""}`}
          >
            <summary className="mb-6 list-none">
              <div className="flex gap-x-3">
                <img className="h-6 w-6" src={commodity.commodity.icon} />
                <h2 className="text-xl font-bold text-can-forest-teal">
                  {commodity.commodity.name}
                </h2>
                <img
                  src={angleSmallDownSvg}
                  className="ml-auto rotate-180 group-open:rotate-0"
                />
              </div>
            </summary>
            <div className="flex flex-col gap-y-4">
              <b className="text-can-midnight-steel">{commodity.headline}</b>
              <div
                className="can-auto-tailwindcss flex flex-col gap-3"
                dangerouslySetInnerHTML={{ __html: commodity.summary }}
              />
              <div
                className="can-auto-tailwindcss flex flex-col gap-3"
                dangerouslySetInnerHTML={{
                  __html: commodity.recommendationText,
                }}
              />
            </div>
            <I18nProvider language={report.data.language} i18n={myI18n}>
              <div className="mt-6 flex flex-col gap-y-2">
                <h3 className="font-bold text-can-forest-teal">
                  <Trans>Výhled</Trans>
                </h3>
                <Recommendation value={commodity.recommendation} />
              </div>
            </I18nProvider>
          </details>
        </React.Fragment>
      ))}
      <p className="my-8 flex flex-wrap justify-center gap-2">
        <Trans>Ve spolupráci s</Trans>
        <a target="_blank" href="https://black-silo.com/" rel="noreferrer">
          <BlackSilo className="h-[32px] w-[79px] shrink-0" />
        </a>
      </p>
      {report.data.isMasked ? <CallToAction /> : null}
    </Container>
  );
};

const CallToAction = () => {
  const { handlePlanPurchaseClick } = useEnforcePlans();

  return (
    <div className="sticky bottom-6 left-0 right-0 flex w-full flex-col gap-4 rounded-b-3xl rounded-t-3xl bg-white p-6 shadow-can-light-box">
      <div className="flex shrink-0 gap-4">
        <div className="flex h-[72px] w-[72px] shrink-0 content-center justify-center self-center rounded-full bg-can-silver-gray">
          <img src={DiamondSvgSrc} className="h-[32px] w-[32px] self-center" />
        </div>
        <div>
          <h3 className="text-can-midnight-steel">
            <strong>
              <Trans>Článek je dostupný ve vyšší verzi aplikace.</Trans>
            </strong>
          </h3>
          <p className="text-xs">
            <Trans>
              Pro zobrazení je nutné aktivní předplatné vyšší verze aplikace.
              Prozkoumejte výhody všech našich balíčků služeb.
            </Trans>
          </p>
        </div>
      </div>
      <div>
        <Button
          onClick={handlePlanPurchaseClick}
          variant="primary"
          className="w-full"
        >
          <Trans>Zobrazit balíčky služeb</Trans>
        </Button>
      </div>
    </div>
  );
};

const Recommendation = ({
  value,
}: {
  value: keyof typeof ReportCommodityToRecommendation;
}) => {
  const { i18n } = useLingui();
  let text = "";
  const icon = {
    src: "",
    className: "",
  };
  switch (value) {
    case "NORMAL":
      text = i18n._(msg`Neočekává se růst ani pokles cen`);
      icon.src = equalSvg;
      // eslint-disable-next-line lingui/no-unlocalized-strings
      icon.className = "h-4 w-4";
      break;
    case "BEARISH":
      text = i18n._(msg`Bearish - očekává se pokles cen`);
      icon.src = bearishSvg;
      break;
    case "BULLISH":
      text = i18n._(msg`Bullish - očekává se růst cen`);
      icon.src = bullishSvg;
      break;
  }

  return (
    <span className="flex items-center gap-2 rounded-2xl bg-can-silver-gray px-4 py-3">
      <img src={icon.src} className={icon.className} alt={text} />
      <b className="mx-auto font-bold text-can-midnight-steel">{text}</b>
    </span>
  );
};

const Container = ({ children }: React.PropsWithChildren) => (
  <FullscreenModalLayout
    headerButton={
      <SearchParamsLink to={getAbsolutePath(reportsPath)} replace>
        <Cross />
      </SearchParamsLink>
    }
  >
    <Spacer className="h-6" />
    {children}
  </FullscreenModalLayout>
);

const Skeleton = () => {
  return (
    <Container>
      <div role="status" className="mx-6 flex flex-col">
        <Spacer className="h-6" />
        <h1 className="h-5 shrink-0 animate-pulse rounded-full bg-gray-200">
          &nbsp;
        </h1>
        <Spacer className="h-6" />
        <div className="flex h-52 w-full animate-pulse items-center justify-center rounded bg-gray-200">
          <img
            className="h-24 animate-pulse opacity-50 grayscale"
            src={newspaperSvg}
          />
        </div>
        <Spacer className="h-6" />
        {Array.from({ length: 5 }).map((_, i) => {
          return (
            <React.Fragment key={i}>
              <div className="h-3 shrink-0 animate-pulse rounded-full bg-gray-200" />
              <Spacer className="h-8" />
            </React.Fragment>
          );
        })}
      </div>
    </Container>
  );
};

export { path, Report, Skeleton };

import CoinUpArrow from "@icons/coin-up-arrow.svg?react";
import EllipseGradient from "@icons/ellipse-gradient.svg?react";
import { DateTime } from "luxon";

type Props = {
  createdAt: string;
  title: string;
  body: string;
};

const Notification = ({ children }: React.PropsWithChildren) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "50px 5fr 1fr",
        gridTemplateRows: "auto auto",
        gridTemplateAreas: "'icon content button' 'datetime datetime datetime'",
      }}
      className="can-notification gap-x-3 rounded-2xl bg-white p-4 font-sans shadow-can-light-box"
    >
      {children}
    </div>
  );
};

const Button = (
  props: React.PropsWithChildren<
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "className">
  >,
) => (
  <button
    style={{ gridArea: "button" }}
    className="-mr-2 ml-auto flex-shrink-0 self-center rounded-full p-2 hover:bg-can-silver-gray"
    {...props}
  ></button>
);

const Content = ({ children }: React.PropsWithChildren) => {
  return (
    <div style={{ gridArea: "content" }} className="flex flex-col">
      {children}
    </div>
  );
};

const Icon = () => (
  <div
    style={{ gridArea: "icon" }}
    className="grid place-content-center place-items-center"
  >
    <EllipseGradient style={{ gridRow: 1, gridColumn: 1 }} />
    <CoinUpArrow
      style={{ gridRow: 1, gridColumn: 1 }}
      className="[&_path]:fill-blue-50"
    />
  </div>
);

const Title = ({ children }: React.PropsWithChildren) => (
  <h3 className="font-bold text-can-midnight-steel">{children}</h3>
);

const Body = ({ children }: React.PropsWithChildren) => (
  <p>
    <small>{children}</small>
  </p>
);

const CreatedAt = ({ children }: { children: DateTime }) => (
  <small style={{ gridArea: "datetime" }} className="text-right ">
    {children.toLocaleString(DateTime.DATETIME_SHORT)}
  </small>
);

Notification.Button = Button;
Notification.Content = Content;
Notification.CreatedAt = CreatedAt;
Notification.Icon = Icon;
Notification.Body = Body;
Notification.Title = Title;

export { Notification };
export type { Props };

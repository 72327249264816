import Cross from "@icons/cross.svg?react";
import PlusSvg from "@icons/plus.svg?react";
import { Trans } from "@lingui/react/macro";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { Button } from "@/common/components/button/button";
import { Dialog, useDialogProvider } from "@/common/components/dialog/dialog";
import { PageTitle } from "@/common/components/page-title/page-title";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { Spacer } from "@/common/components/spacer/spacer";
import { useCurrency } from "@/common/hooks/use-currency";

import { FullscreenModalLayout } from "../layout/layout.fullscreen";
import { handleFormError } from "../shared/api/error-handler";
import { storageQuery } from "../shared/api/get-storage";
import {
  companiesQuery,
  useAddStorage,
  useArchiveStorage,
  useEditStorage,
} from "./api";
import { CommodityCard } from "./components/commodity-card/commodity-card";
import { Fields, StorageForm } from "./components/storage-form/storage-form";

const path = "new";

const ExistingStorage = () => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(id, "Storage id should be defined");
  const storage = useSuspenseQuery(storageQuery(id));
  const editStorage = useEditStorage();
  const companies = useSuspenseQuery(companiesQuery());
  const form = useForm<Fields>({
    defaultValues: {
      name: storage.data.name,
      address: storage.data.address,
      companyId: storage.data.company.id,
      postalCode: storage.data.postalCode,
    },
  });
  const [, currency] = useCurrency();
  const navigate = useNavigate();
  const archiveStorage = useArchiveStorage();
  const { closeDialog: closeDeleteDialog, openDialog: openDeleteDialog } =
    useDialogProvider();

  const handleSubmitStorage = (data: Fields) => {
    editStorage.mutate(
      {
        id,
        storage: {
          address: data.address,
          name: data.name,
          companyId: data.companyId,
          zip: data.postalCode,
        },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
        },
      },
    );
  };

  const handleDeleteStorage = () => {
    archiveStorage.mutate(
      { id },
      {
        onSettled: async () => {
          await queryClient.invalidateQueries();
          closeDeleteDialog();
        },
        onSuccess: () => {
          navigate("..");
        },
      },
    );
  };

  const DeleteDialog = (
    <Dialog>
      <Dialog.Title>
        <Trans>Opravdu chcete smazat sklad {storage.data.name}?</Trans>
      </Dialog.Title>
      <Dialog.Actions>
        <Dialog.Confirm type="button" onClick={handleDeleteStorage}>
          <Trans>Ano</Trans>
        </Dialog.Confirm>
        <Dialog.Cancel type="button" onClick={closeDeleteDialog}>
          <Trans>Ne</Trans>
        </Dialog.Cancel>
      </Dialog.Actions>
    </Dialog>
  );

  return (
    <FullscreenModalLayout
      headerButton={
        <SearchParamsLink to=".." replace>
          <Cross />
        </SearchParamsLink>
      }
    >
      <Spacer className="h-6" />
      <PageTitle>{storage.data.name}</PageTitle>
      <Spacer className="h-6" />
      <StorageForm
        buttons={
          <>
            <Button.Delete
              disabled={archiveStorage.status === "pending"}
              onClick={() => openDeleteDialog(DeleteDialog)}
              type="button"
            >
              <Trans>Smazat sklad</Trans>
            </Button.Delete>
            <Button
              form="storage-form"
              variant="primary"
              type="submit"
              disabled={editStorage.status === "pending"}
            >
              <Trans>Uložit sklad</Trans>
            </Button>
          </>
        }
        form={form}
        onSubmit={handleSubmitStorage}
        storage={{
          companies: companies.data ?? [],
        }}
      >
        <h2 className="font-bold text-can-forest-teal">
          <Trans>Komodity skladem</Trans>
        </h2>
        {storage.data.offers.map((offer) => (
          <SearchParamsLink key={offer.id} to={`offers/${offer.id}`}>
            <CommodityCard>
              <CommodityCard.Title
                crop={{
                  harvestYear: offer.harvestYear,
                  name: offer.crop.name,
                  mark: false,
                }}
              />
              <CommodityCard.Price tons={offer.amount} currency={currency} />
              <CommodityCard.Stored tons={offer.amount} />
              <CommodityCard.ProgressBar
                values={{
                  orderAmount: offer.orderAmount,
                  totalAmount: offer.totalAmount,
                  freeAmount: offer.amount,
                }}
              />
              <CommodityCard.Available
                order={offer.orderAmount}
                available={offer.totalAmount}
                tons={offer.amount}
              />
            </CommodityCard>
          </SearchParamsLink>
        ))}
        <SearchParamsLink
          className="flex items-center justify-center gap-2"
          to="crops/new"
        >
          <PlusSvg />
          <Trans>Přidat komoditu</Trans>
        </SearchParamsLink>
      </StorageForm>
    </FullscreenModalLayout>
  );
};

const NewStorage = () => {
  const queryClient = useQueryClient();
  const companies = useSuspenseQuery(companiesQuery());
  const initialValues: Fields = {
    name: "",
    address: "",
    companyId: companies.data.at(0)?.id ?? "",
    postalCode: "",
  };
  const addStorage = useAddStorage();
  const form = useForm<Fields>({
    defaultValues: initialValues,
  });
  const navigate = useNavigate();

  const handleSubmitStorage = (data: Fields) => {
    addStorage.mutate(
      {
        name: data.name,
        address: data.address,
        companyId: data.companyId,
        zip: data.postalCode,
      },
      {
        onSuccess: async (data) => {
          await queryClient.invalidateQueries();
          navigate(`../${data.addStorage?.id}`);
        },
        onError: (e: Error) => {
          const errors = handleFormError(e);

          errors.forEach((error) => {
            if (error.field.includes("label")) {
              form.setError("name", { message: error.error.join("\n") });
            }

            if (error.field.includes("address")) {
              form.setError("address", { message: error.error.join("\n") });
            }
          });
        },
      },
    );
  };

  return (
    <FullscreenModalLayout
      headerButton={
        <SearchParamsLink to=".." replace>
          <Cross />
        </SearchParamsLink>
      }
    >
      <Spacer className="h-6" />
      <PageTitle>
        <Trans>Přidat sklad</Trans>
      </PageTitle>
      <Spacer className="h-6" />
      <StorageForm
        buttons={
          <Button
            form="storage-form"
            variant="primary"
            type="submit"
            disabled={addStorage.status === "pending"}
          >
            <Trans>Uložit a přidat sklad</Trans>
          </Button>
        }
        form={form}
        onSubmit={handleSubmitStorage}
        storage={{
          companies: companies.data ?? [],
        }}
      ></StorageForm>
    </FullscreenModalLayout>
  );
};

export { ExistingStorage, NewStorage, path };

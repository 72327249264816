import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { PermissionGuard, useHasPermission } from "@/common/acl/guard/guard";
import { Dialog } from "@/common/components/dialog/dialog";
import { Role } from "@/common/services/user";
import { CompanySimpleUserToApprovalState } from "@/generated/api/users";

import { Table } from "../components/table/table";
import type { User as TUser } from "../components/user/user";
import {
  useConfirmedAction,
  User as UserComponent,
} from "../components/user/user";
import { useDeleteUserMutation } from "../components/user/user.api";
import { UserCompanyAction } from "../components/user-actions/user-company-action";
import { UserRoleAction } from "../components/user-actions/user-role-action";
import { UserStateAction } from "../components/user-actions/user-state-action";
import { useBlockUserMutation, userQuery } from "./user.api";

const User = () => {
  const { id } = useParams();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(id, "user id is missing");
  const user = useSuspenseQuery(
    userQuery({ ssoUserId: encodeURIComponent(id) }),
  );
  const data: TUser = {
    email: user.data.email,
    ssoUserId: user.data.id,
    name: user.data.name,
    phone: user.data.phone,
    metadata: user.data.userMetadata,
  };

  return (
    <UserComponent user={data}>
      <DangerZone user={{ ...data, blocked: user.data.isBlocked }} />
      <Companies
        companies={user.data.company.map((company) => ({
          role: company.role,
          userCompanyRelationId: company.id,
          name: company.companyName ?? "",
          approvalState: company.approvalState,
        }))}
      />
    </UserComponent>
  );
};

type Company = {
  userCompanyRelationId: number;
  name: string;
  approvalState: CompanySimpleUserToApprovalState;
  role: Role;
};

const columnHelper = createColumnHelper<Company>();

const Companies = ({ companies }: { companies: Array<Company> }) => {
  const hasCompanyApprovePermission = useHasPermission("users:company:approve");
  const hasUserCompanyDeletePermission = useHasPermission(
    "users:company:delete",
  );
  const hasCompanyRolePermission = useHasPermission("users:company:role");
  const columns = React.useMemo(
    () => [
      columnHelper.accessor("userCompanyRelationId", {
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("name", {
        header: () => t`Společnost`,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("approvalState", {}),
      columnHelper.accessor("role", {}),
      columnHelper.display({
        id: "state-action",
        header: () => t`Stav`,
        cell: hasCompanyApprovePermission
          ? ({ row }) => (
              <UserStateAction
                initialStatus={row.getValue("approvalState")}
                userCompanyRelationId={row.getValue("userCompanyRelationId")}
              />
            )
          : ({ row }) => row.getValue("approvalState"),
      }),
      columnHelper.display({
        id: "role-action",
        header: () => t`Role`,
        cell: hasCompanyRolePermission
          ? ({ row }) => (
              <UserRoleAction
                userCompanyRelationId={row.getValue("userCompanyRelationId")}
                initialRole={row.getValue("role")}
              />
            )
          : ({ row }) => row.getValue("role"),
      }),

      ...(hasUserCompanyDeletePermission
        ? [
            columnHelper.display({
              id: "user-company-action",
              header: () => t`Odebrat`,
              cell: ({ row }) => (
                <UserCompanyAction userCompanyRelationId={row.getValue("id")} />
              ),
            }),
          ]
        : []),
    ],
    [
      hasCompanyApprovePermission,
      hasCompanyRolePermission,
      hasUserCompanyDeletePermission,
    ],
  );
  const table = useReactTable({
    data: companies,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        userCompanyRelationId: false,
        approvalState: false,
        role: false,
      },
    },
  });

  return (
    <>
      <hr className="mt-4" />
      {companies.length > 0 ? (
        <Table>
          <Table.Head table={table} />
          <Table.Body table={table} />
        </Table>
      ) : (
        <Trans>Uživatel není přiřazen k žádné společnosti</Trans>
      )}
    </>
  );
};

const DangerZone = ({ user }: { user: TUser & { blocked: boolean } }) => {
  const { id } = useParams();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(id, "user id is missing");
  const canDeleteUser = useHasPermission("users:user:delete");
  const deleteUser = useDeleteUserMutation();
  const canBlockUser = useHasPermission("users:user:block");
  const blockUser = useBlockUserMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { runWhenConfirmed, dialog, setDialog, rejectRef, resolveRef } =
    useConfirmedAction();

  const handleDeleteUser = () => {
    deleteUser.mutate(
      {
        ssoUserId: user.ssoUserId,
      },
      {
        onSuccess: () => {
          setDialog(undefined);
          navigate({
            pathname: "..",
            search: location.search,
          });
          void queryClient.invalidateQueries();
        },
        onError: () => {
          alert(t`Uživatele se nepodařilo smazat`);
        },
      },
    );
  };

  const handleBlockUser = () => {
    blockUser.mutate(
      { ssoUserId: user.ssoUserId, blocked: !user.blocked },
      {
        onSuccess: () => {
          setDialog(undefined);
          navigate({
            pathname: "..",
            search: location.search,
          });
          void queryClient.invalidateQueries();
        },
        onError: () => {
          alert(t`Uživatele se nepodařilo zablokovat`);
        },
      },
    );
  };

  return canDeleteUser || canBlockUser ? (
    <>
      <details className="text-red-500">
        <summary>
          <Trans>Nebezpečná zóna</Trans>
        </summary>
        <div className="mt-4 flex flex-col gap-y-4">
          <PermissionGuard permission="users:user:delete">
            <button
              onClick={runWhenConfirmed(
                <Trans>
                  Tímto zásahem úplně smažete uživatele {user.name}.
                </Trans>,
                handleDeleteUser,
              )}
              className="rounded-lg border border-red-500 p-2 hover:bg-red-500 hover:text-white"
            >
              <Trans>Smazat uživatele</Trans>
            </button>
          </PermissionGuard>
          <PermissionGuard permission="users:user:block">
            <button
              onClick={runWhenConfirmed(
                user.blocked ? (
                  <Trans>
                    Tímto zásahem odblokujete uživatele {user.name}.
                  </Trans>
                ) : (
                  <Trans>
                    Tímto zásahem zablokujete uživatele {user.name}.
                  </Trans>
                ),
                handleBlockUser,
              )}
              className="rounded-lg border border-red-500 p-2 hover:bg-red-500 hover:text-white"
            >
              {user.blocked ? (
                <Trans>Odblokovat uživatele</Trans>
              ) : (
                <Trans>Zablokovat uživatele</Trans>
              )}
            </button>
          </PermissionGuard>
        </div>
      </details>
      {dialog ? (
        <Dialog>
          <Dialog.Title>
            <Trans>Opravdu chcete provést akci?</Trans>
          </Dialog.Title>
          <p className="text-red-500">{dialog}</p>
          <Dialog.Actions>
            <Dialog.Confirm
              disabled={
                deleteUser.status === "pending" ||
                blockUser.status === "pending"
              }
              onClick={resolveRef.current}
            >
              <Trans>Ano</Trans>
            </Dialog.Confirm>
            <Dialog.Cancel onClick={rejectRef.current}>
              <Trans>Ne</Trans>
            </Dialog.Cancel>
          </Dialog.Actions>
        </Dialog>
      ) : null}
    </>
  ) : null;
};

export { User };

import { useAuth0 } from "@auth0/auth0-react";
import invariant from "tiny-invariant";

import { getUser, UserSchema } from "../services/user";

/**
 * Right now [2025-20-01] there is only one country where it is possible to use the platform without
 * being a member of a company. And that country is Ireland.
 *
 * Otherwise, we would strictly fail the `getFirstCompany` call when no company was found.
 */

const useFirstCompany = () => {
  const { user: _user } = useAuth0();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(_user, "User should be defined");
  const user = getUser(_user);

  return getFirstCompany(user);
};

const getFirstCompany = (user: UserSchema) => {
  const company = user["can/app_metadata"]?.companies?.at(0);
  return company;
};

const getRequiredFirstCompany = (user: UserSchema) => {
  const company = getFirstCompany(user);
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(company, "You have to be a company member to get here");
  return company;
};

const useRequiredFirstCompany = () => {
  const { user: _user } = useAuth0();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(_user, "User should be defined");
  const user = getUser(_user);

  return getRequiredFirstCompany(user);
};

export {
  getFirstCompany,
  getRequiredFirstCompany,
  useFirstCompany,
  useRequiredFirstCompany,
};

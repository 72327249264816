/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Clever Analytics Users Public API
 * OpenAPI spec version: 1.0.0
 */
import { usersInstance } from '../../api/client';
export type ExportUsersApprovalState = typeof ExportUsersApprovalState[keyof typeof ExportUsersApprovalState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExportUsersApprovalState = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  BLOCKED: 'BLOCKED',
} as const;

export type ExportUsersParams = {
/**
 * Search in user email, company name and number
 */
search?: string;
/**
 * User company relation approval state
 */
approvalState?: ExportUsersApprovalState;
};

export type GetUsers1ApprovalState = typeof GetUsers1ApprovalState[keyof typeof GetUsers1ApprovalState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsers1ApprovalState = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  BLOCKED: 'BLOCKED',
} as const;

export type GetUsers1SortDir = typeof GetUsers1SortDir[keyof typeof GetUsers1SortDir];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsers1SortDir = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export type GetUsers1SortCol = typeof GetUsers1SortCol[keyof typeof GetUsers1SortCol];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsers1SortCol = {
  ID: 'ID',
  EMAIL: 'EMAIL',
  CREATED: 'CREATED',
} as const;

export type GetUsers1Params = {
/**
 * Page number (starting as 1)
 */
page?: string;
/**
 * Page size (default: 100)
 */
size?: string;
sortCol?: GetUsers1SortCol;
sortDir?: GetUsers1SortDir;
/**
 * Search in user email, company name and number
 */
search?: string;
/**
 * User company relation approval state
 */
approvalState?: GetUsers1ApprovalState;
};

export type GetCompanyUsersStateItem = typeof GetCompanyUsersStateItem[keyof typeof GetCompanyUsersStateItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanyUsersStateItem = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  BLOCKED: 'BLOCKED',
} as const;

export type GetCompanyUsersParams = {
state?: GetCompanyUsersStateItem[];
};

export type GetCompaniesPlanSource = typeof GetCompaniesPlanSource[keyof typeof GetCompaniesPlanSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompaniesPlanSource = {
  TRIAL: 'TRIAL',
  MANUAL: 'MANUAL',
  DEFAULT: 'DEFAULT',
  PAID: 'PAID',
} as const;

export type GetCompaniesPlan = typeof GetCompaniesPlan[keyof typeof GetCompaniesPlan];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompaniesPlan = {
  BASIC: 'BASIC',
  PLUS: 'PLUS',
  PREMIUM: 'PREMIUM',
} as const;

export type GetCompaniesSortDir = typeof GetCompaniesSortDir[keyof typeof GetCompaniesSortDir];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompaniesSortDir = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export type GetCompaniesSortCol = typeof GetCompaniesSortCol[keyof typeof GetCompaniesSortCol];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompaniesSortCol = {
  ID: 'ID',
  NAME: 'NAME',
  PLAN_EXPIRATION: 'PLAN_EXPIRATION',
} as const;

export type GetCompaniesParams = {
/**
 * Page number (starting as 1)
 */
page?: string;
/**
 * Page size (default: 100)
 */
size?: string;
sortCol?: GetCompaniesSortCol;
sortDir?: GetCompaniesSortDir;
/**
 * Company country
 */
country?: string;
/**
 * Search in company name and number
 */
search?: string;
/**
 * Plan type
 */
plan?: GetCompaniesPlan;
/**
 * Plan source
 */
planSource?: GetCompaniesPlanSource;
/**
 * Plan is (not) expired 
 */
planValid?: boolean;
};

export type GetCompanyPaymentsCurrency = typeof GetCompanyPaymentsCurrency[keyof typeof GetCompanyPaymentsCurrency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanyPaymentsCurrency = {
  CZK: 'CZK',
  EUR: 'EUR',
  GBP: 'GBP',
  RON: 'RON',
  USD: 'USD',
} as const;

export type GetCompanyPaymentsGateway = typeof GetCompanyPaymentsGateway[keyof typeof GetCompanyPaymentsGateway];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanyPaymentsGateway = {
  STRIPE: 'STRIPE',
  BANK: 'BANK',
} as const;

export type GetCompanyPaymentsPlanType = typeof GetCompanyPaymentsPlanType[keyof typeof GetCompanyPaymentsPlanType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanyPaymentsPlanType = {
  BASIC: 'BASIC',
  PLUS: 'PLUS',
  PREMIUM: 'PREMIUM',
} as const;

export type GetCompanyPaymentsPaymentStatus = typeof GetCompanyPaymentsPaymentStatus[keyof typeof GetCompanyPaymentsPaymentStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanyPaymentsPaymentStatus = {
  _INITIALIZED: ' INITIALIZED',
  _WAITING: ' WAITING',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
  CANCELLED: 'CANCELLED',
  REFUNDED: 'REFUNDED',
} as const;

export type GetCompanyPaymentsPaymentType = typeof GetCompanyPaymentsPaymentType[keyof typeof GetCompanyPaymentsPaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanyPaymentsPaymentType = {
  CARD: 'CARD',
  BANK_TRANSFER: 'BANK_TRANSFER',
} as const;

export type GetCompanyPaymentsSortDir = typeof GetCompanyPaymentsSortDir[keyof typeof GetCompanyPaymentsSortDir];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanyPaymentsSortDir = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export type GetCompanyPaymentsSortCol = typeof GetCompanyPaymentsSortCol[keyof typeof GetCompanyPaymentsSortCol];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanyPaymentsSortCol = {
  ID: 'ID',
} as const;

export type GetCompanyPaymentsParams = {
/**
 * Page number (starting as 1)
 */
page?: string;
/**
 * Page size (default: 100)
 */
size?: string;
sortCol?: GetCompanyPaymentsSortCol;
sortDir?: GetCompanyPaymentsSortDir;
/**
 * Search in gateway id
 */
search?: string;
/**
 * Payment type
 */
paymentType?: GetCompanyPaymentsPaymentType;
/**
 * Payment type
 */
paymentStatus?: GetCompanyPaymentsPaymentStatus;
/**
 * Plan type
 */
planType?: GetCompanyPaymentsPlanType;
/**
 * Gateway
 */
gateway?: GetCompanyPaymentsGateway;
/**
 * Currency
 */
currency?: GetCompanyPaymentsCurrency;
};

export type GetPaymentsCurrency = typeof GetPaymentsCurrency[keyof typeof GetPaymentsCurrency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPaymentsCurrency = {
  CZK: 'CZK',
  EUR: 'EUR',
  GBP: 'GBP',
  RON: 'RON',
  USD: 'USD',
} as const;

export type GetPaymentsGateway = typeof GetPaymentsGateway[keyof typeof GetPaymentsGateway];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPaymentsGateway = {
  STRIPE: 'STRIPE',
  BANK: 'BANK',
} as const;

export type GetPaymentsPlanType = typeof GetPaymentsPlanType[keyof typeof GetPaymentsPlanType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPaymentsPlanType = {
  BASIC: 'BASIC',
  PLUS: 'PLUS',
  PREMIUM: 'PREMIUM',
} as const;

export type GetPaymentsPaymentStatus = typeof GetPaymentsPaymentStatus[keyof typeof GetPaymentsPaymentStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPaymentsPaymentStatus = {
  _INITIALIZED: ' INITIALIZED',
  _WAITING: ' WAITING',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
  CANCELLED: 'CANCELLED',
  REFUNDED: 'REFUNDED',
} as const;

export type GetPaymentsPaymentType = typeof GetPaymentsPaymentType[keyof typeof GetPaymentsPaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPaymentsPaymentType = {
  CARD: 'CARD',
  BANK_TRANSFER: 'BANK_TRANSFER',
} as const;

export type GetPaymentsSortDir = typeof GetPaymentsSortDir[keyof typeof GetPaymentsSortDir];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPaymentsSortDir = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export type GetPaymentsSortCol = typeof GetPaymentsSortCol[keyof typeof GetPaymentsSortCol];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPaymentsSortCol = {
  ID: 'ID',
} as const;

export type GetPaymentsParams = {
/**
 * Page number (starting as 1)
 */
page?: string;
/**
 * Page size (default: 100)
 */
size?: string;
sortCol?: GetPaymentsSortCol;
sortDir?: GetPaymentsSortDir;
/**
 * Search in gateway id
 */
search?: string;
/**
 * Payment type
 */
paymentType?: GetPaymentsPaymentType;
/**
 * Payment type
 */
paymentStatus?: GetPaymentsPaymentStatus;
/**
 * Plan type
 */
planType?: GetPaymentsPlanType;
/**
 * Gateway
 */
gateway?: GetPaymentsGateway;
/**
 * Currency
 */
currency?: GetPaymentsCurrency;
};

export type GetCompanyFeatures200 = {[key: string]: CompanyFeatureTo};

export type GetCompanyPlansParams = {
/**
 * Feature is shown in menu 
 */
inMenu?: boolean;
/**
 * Feature is shown in plan 
 */
inPlan?: boolean;
};

export type GetCountryPlansParams = {
/**
 * Feature is shown in menu 
 */
inMenu?: boolean;
/**
 * Feature is shown in plan 
 */
inPlan?: boolean;
};

export type GetDefaultFeatures200 = {[key: string]: CompanyFeatureTo};

export type GetPlanParams = {
/**
 * Feature is shown in menu 
 */
inMenu?: boolean;
/**
 * Feature is shown in plan 
 */
inPlan?: boolean;
};

export type GetInternalCompanyFeatures200 = {[key: string]: CompanyFeatureTo};

export type GetUsersSortDir = typeof GetUsersSortDir[keyof typeof GetUsersSortDir];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersSortDir = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export type GetUsersSortCol = typeof GetUsersSortCol[keyof typeof GetUsersSortCol];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersSortCol = {
  EMAIL: 'EMAIL',
  NAME: 'NAME',
  CREATED: 'CREATED',
} as const;

export type GetUsersParams = {
/**
 * Page number (starting as 1)
 */
page?: string;
/**
 * Page size (default: 100)
 */
size?: string;
sortCol?: GetUsersSortCol;
sortDir?: GetUsersSortDir;
/**
 * Search in name and email
 */
search?: string;
/**
 * User country
 */
country?: string;
};

export interface StreamingResponseBody { [key: string]: unknown }

export type CompanyUserToRole = typeof CompanyUserToRole[keyof typeof CompanyUserToRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUserToRole = {
  USER: 'USER',
  STATUTORY: 'STATUTORY',
} as const;

export type CompanyUserToGrantsItem = typeof CompanyUserToGrantsItem[keyof typeof CompanyUserToGrantsItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUserToGrantsItem = {
  COMPANY_DETAIL_EDIT: 'COMPANY_DETAIL_EDIT',
  PAYMENT_CREATE: 'PAYMENT_CREATE',
  CONTRACT_SIGN: 'CONTRACT_SIGN',
} as const;

export type CompanyUserToApprovalState = typeof CompanyUserToApprovalState[keyof typeof CompanyUserToApprovalState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUserToApprovalState = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  BLOCKED: 'BLOCKED',
} as const;

export interface CompanyUserTo {
  approvalState: CompanyUserToApprovalState;
  companyCountry?: string;
  companyId: number;
  companyName?: string;
  companyNumber?: string;
  companyPlan: PlanTo;
  createdAt: string;
  grants: CompanyUserToGrantsItem[];
  id: number;
  role: CompanyUserToRole;
  userEmail?: string;
  userId: string;
  userMetadata: UserMetadata;
  userName?: string;
}

export interface CompanyUserPage {
  data: CompanySimpleUserTo[];
  totalSize: number;
}

export type CompanyToPlanSource = typeof CompanyToPlanSource[keyof typeof CompanyToPlanSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyToPlanSource = {
  TRIAL: 'TRIAL',
  DEFAULT: 'DEFAULT',
  MANUAL: 'MANUAL',
  PAID: 'PAID',
} as const;

export interface CompanyTo {
  companyNumber: string;
  country: string;
  detail?: CompanyDetailTo;
  id: number;
  name: string;
  plan?: PlanTo;
  planSource: CompanyToPlanSource;
  subscriptions: SubscriptionTo[];
}

export interface CompanyPage {
  data: CompanyTo[];
  totalSize: number;
}

export type CountryToPopulatorFieldsItem = typeof CountryToPopulatorFieldsItem[keyof typeof CountryToPopulatorFieldsItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CountryToPopulatorFieldsItem = {
  VAT: 'VAT',
  FORM: 'FORM',
  ADDRESS: 'ADDRESS',
} as const;

export type CountryToPaymentMethodsItem = typeof CountryToPaymentMethodsItem[keyof typeof CountryToPaymentMethodsItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CountryToPaymentMethodsItem = {
  CARD: 'CARD',
  BANK_TRANSFER: 'BANK_TRANSFER',
} as const;

export interface CountryTo {
  currency: CurrencyTo;
  hasCompanyPopulator: boolean;
  hasCompanyValidator: boolean;
  id: string;
  isAuthorized: boolean;
  isCompanyRequired: boolean;
  language: string;
  localName: string;
  name: string;
  paymentMethods: CountryToPaymentMethodsItem[];
  phoneCountryCode: string;
  populatorFields: CountryToPopulatorFieldsItem[];
}

export interface PaymentPage {
  data: PaymentTo[];
  totalSize: number;
}

export interface UserPage {
  data: UserTo[];
  totalSize: number;
}

export type CompanyFeatureToPlanType = typeof CompanyFeatureToPlanType[keyof typeof CompanyFeatureToPlanType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyFeatureToPlanType = {
  BASIC: 'BASIC',
  PLUS: 'PLUS',
  PREMIUM: 'PREMIUM',
} as const;

export type CompanyFeatureToMode = typeof CompanyFeatureToMode[keyof typeof CompanyFeatureToMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyFeatureToMode = {
  TRIAL: 'TRIAL',
  FULL: 'FULL',
} as const;

export interface CompanyFeatureTo {
  menu?: boolean;
  mode?: CompanyFeatureToMode;
  planId?: number;
  planType?: CompanyFeatureToPlanType;
}

export type CompanyFeatureModeToMode = typeof CompanyFeatureModeToMode[keyof typeof CompanyFeatureModeToMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyFeatureModeToMode = {
  TRIAL: 'TRIAL',
  FULL: 'FULL',
} as const;

export interface CompanyFeatureModeTo {
  mode?: CompanyFeatureModeToMode;
}

export interface UserMetadata {
  can: CanMetadata;
  common: CommonMetadata;
}

export interface UserTo {
  company: CompanySimpleUserTo[];
  createdAt: string;
  email: string;
  id: string;
  identities: string[];
  isBlocked: boolean;
  lastLogin?: string;
  loginsCount: number;
  name: string;
  phone?: PhoneTo;
  userMetadata: UserMetadata;
}

export interface PhoneMetadata {
  countryCode?: string;
  phoneNumber?: string;
}

export interface NewsMetadata {
  language: string;
}

export interface SettingsMetadata {
  language: string;
  news: NewsMetadata;
}

export type CompanySimpleUserToRole = typeof CompanySimpleUserToRole[keyof typeof CompanySimpleUserToRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanySimpleUserToRole = {
  USER: 'USER',
  STATUTORY: 'STATUTORY',
} as const;

export type CompanySimpleUserToGrantsItem = typeof CompanySimpleUserToGrantsItem[keyof typeof CompanySimpleUserToGrantsItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanySimpleUserToGrantsItem = {
  COMPANY_DETAIL_EDIT: 'COMPANY_DETAIL_EDIT',
  PAYMENT_CREATE: 'PAYMENT_CREATE',
  CONTRACT_SIGN: 'CONTRACT_SIGN',
} as const;

export type CompanySimpleUserToApprovalState = typeof CompanySimpleUserToApprovalState[keyof typeof CompanySimpleUserToApprovalState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanySimpleUserToApprovalState = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  BLOCKED: 'BLOCKED',
} as const;

export interface CompanySimpleUserTo {
  approvalState: CompanySimpleUserToApprovalState;
  companyCountry?: string;
  companyId: number;
  companyName?: string;
  companyNumber?: string;
  companyPlan: PlanTo;
  createdAt: string;
  grants: CompanySimpleUserToGrantsItem[];
  id: number;
  role: CompanySimpleUserToRole;
  userEmail?: string;
  userId: string;
}

export interface CompanyMetadata {
  companyName?: string;
  companyNumber?: string;
  countryCode?: string;
}

export interface CommonMetadata {
  phone: PhoneMetadata;
}

export interface CanMetadata {
  companies?: CompanyMetadata[];
  settings: SettingsMetadata;
}

export interface LanguageTo {
  id: string;
  isDefault: boolean;
  localName: string;
  name: string;
}

export type CompanyPatchRequestCompanyForm = typeof CompanyPatchRequestCompanyForm[keyof typeof CompanyPatchRequestCompanyForm];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyPatchRequestCompanyForm = {
  NATURAL_PERSON: 'NATURAL_PERSON',
  JURIDICAL_PERSON: 'JURIDICAL_PERSON',
} as const;

export interface CompanyPatchRequest {
  address?: string;
  companyForm?: CompanyPatchRequestCompanyForm;
  contactEmail?: string;
  contactName?: string;
  contactPhone?: PhoneUpdateRequest;
  contactPosition?: string;
  vat?: string;
}

export interface UserPatchRequest {
  company?: UserUpdateCompanyRequest;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language?: string;
  name?: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  newsLanguage?: string;
  phone?: PhoneUpdateRequest;
}

export interface CompanyValidationResponse {
  name?: string;
}

export interface CompanyValidationRequest {
  companyNumber: string;
  countryCode: string;
}

export interface SubscriptionCreateManualRequest {
  companyId: number;
  expiresAt: string;
  plan: number;
}

export type PaymentToType = typeof PaymentToType[keyof typeof PaymentToType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentToType = {
  CARD: 'CARD',
  BANK_TRANSFER: 'BANK_TRANSFER',
} as const;

export type PaymentToStatus = typeof PaymentToStatus[keyof typeof PaymentToStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentToStatus = {
  INITIALIZED: 'INITIALIZED',
  WAITING: 'WAITING',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
  CANCELLED: 'CANCELLED',
  REFUNDED: 'REFUNDED',
} as const;

export type PaymentToGateway = typeof PaymentToGateway[keyof typeof PaymentToGateway];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentToGateway = {
  STRIPE: 'STRIPE',
  BANK: 'BANK',
} as const;

export type PaymentSubscriptionToStatus = typeof PaymentSubscriptionToStatus[keyof typeof PaymentSubscriptionToStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentSubscriptionToStatus = {
  INCOMPLETE: 'INCOMPLETE',
  ACTIVE: 'ACTIVE',
  PAST_DUE: 'PAST_DUE',
  CANCELLED: 'CANCELLED',
  EXPIRED: 'EXPIRED',
  DELETED: 'DELETED',
} as const;

export type PaymentSubscriptionToPlanType = typeof PaymentSubscriptionToPlanType[keyof typeof PaymentSubscriptionToPlanType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentSubscriptionToPlanType = {
  BASIC: 'BASIC',
  PLUS: 'PLUS',
  PREMIUM: 'PREMIUM',
} as const;

export type PaymentSubscriptionToPlanPeriod = typeof PaymentSubscriptionToPlanPeriod[keyof typeof PaymentSubscriptionToPlanPeriod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentSubscriptionToPlanPeriod = {
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  YEARLY: 'YEARLY',
} as const;

export interface PaymentSubscriptionTo {
  id: number;
  planPeriod?: PaymentSubscriptionToPlanPeriod;
  planType: PaymentSubscriptionToPlanType;
  status: PaymentSubscriptionToStatus;
  statusUpdatedAt: string;
}

export interface PaymentStripeTo {
  clientSecret: string;
  publicKey: string;
}

export interface PaymentDetailsTo {
  bank?: PaymentBankTo;
  stripe?: PaymentStripeTo;
}

export interface PaymentCompanyTo {
  companyNumber: string;
  id: number;
  name: string;
}

export interface PaymentBillingPhoneTo {
  countryCode: string;
  phoneNumber: string;
}

export interface PaymentBillingTo {
  address: string;
  email: string;
  phone: PaymentBillingPhoneTo;
  vat?: string;
}

export interface PaymentBankTo {
  account: string;
  iban: string;
  swift: string;
  variableSymbol: string;
}

export type InvoiceToType = typeof InvoiceToType[keyof typeof InvoiceToType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceToType = {
  PAYMENT_REQUEST: 'PAYMENT_REQUEST',
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
} as const;

export interface InvoiceTo {
  createdAt: string;
  id: number;
  number: string;
  paymentId: number;
  type: InvoiceToType;
  variable: string;
}

export interface PaymentTo {
  amount: number;
  amountTax: number;
  amountTaxPercent: number;
  amountTotal: number;
  billing: PaymentBillingTo;
  company: PaymentCompanyTo;
  createdAt: string;
  currency: CurrencyTo;
  details?: PaymentDetailsTo;
  gateway: PaymentToGateway;
  gatewayId?: string;
  id: number;
  invoice: InvoiceTo[];
  periodEnd: string;
  periodStart: string;
  status: PaymentToStatus;
  subscription: PaymentSubscriptionTo;
  type: PaymentToType;
}

export type SubscriptionCreatePaidRequestPaymentType = typeof SubscriptionCreatePaidRequestPaymentType[keyof typeof SubscriptionCreatePaidRequestPaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionCreatePaidRequestPaymentType = {
  CARD: 'CARD',
  BANK_TRANSFER: 'BANK_TRANSFER',
} as const;

export interface SubscriptionCreatePaidBillingRequest {
  address: string;
  email: string;
  phone: PhoneUpdateRequest;
  vat?: string;
}

export interface SubscriptionCreatePaidRequest {
  billing: SubscriptionCreatePaidBillingRequest;
  paymentType: SubscriptionCreatePaidRequestPaymentType;
  periodId: number;
}

export type CompanyUserAppMetadataRole = typeof CompanyUserAppMetadataRole[keyof typeof CompanyUserAppMetadataRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUserAppMetadataRole = {
  USER: 'USER',
  STATUTORY: 'STATUTORY',
} as const;

export type CompanyUserAppMetadataGrantsItem = typeof CompanyUserAppMetadataGrantsItem[keyof typeof CompanyUserAppMetadataGrantsItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUserAppMetadataGrantsItem = {
  COMPANY_DETAIL_EDIT: 'COMPANY_DETAIL_EDIT',
  PAYMENT_CREATE: 'PAYMENT_CREATE',
  CONTRACT_SIGN: 'CONTRACT_SIGN',
} as const;

export type CompanyUserAppMetadataApprovalState = typeof CompanyUserAppMetadataApprovalState[keyof typeof CompanyUserAppMetadataApprovalState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyUserAppMetadataApprovalState = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  BLOCKED: 'BLOCKED',
} as const;

export interface CompanyUserAppMetadata {
  approvalState: CompanyUserAppMetadataApprovalState;
  grants: CompanyUserAppMetadataGrantsItem[];
  role: CompanyUserAppMetadataRole;
  userEmail?: string;
  userId: string;
}

export type CompanyPlanAppMetadataType = typeof CompanyPlanAppMetadataType[keyof typeof CompanyPlanAppMetadataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyPlanAppMetadataType = {
  BASIC: 'BASIC',
  PLUS: 'PLUS',
  PREMIUM: 'PREMIUM',
} as const;

export type CompanyPlanAppMetadataSource = typeof CompanyPlanAppMetadataSource[keyof typeof CompanyPlanAppMetadataSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyPlanAppMetadataSource = {
  TRIAL: 'TRIAL',
  DEFAULT: 'DEFAULT',
  MANUAL: 'MANUAL',
  PAID: 'PAID',
} as const;

export interface CompanyPlanAppMetadata {
  planId?: number;
  source: CompanyPlanAppMetadataSource;
  type: CompanyPlanAppMetadataType;
}

export type CompanyAppMetadataRole = typeof CompanyAppMetadataRole[keyof typeof CompanyAppMetadataRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyAppMetadataRole = {
  USER: 'USER',
  STATUTORY: 'STATUTORY',
} as const;

export type CompanyAppMetadataGrantsItem = typeof CompanyAppMetadataGrantsItem[keyof typeof CompanyAppMetadataGrantsItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyAppMetadataGrantsItem = {
  COMPANY_DETAIL_EDIT: 'COMPANY_DETAIL_EDIT',
  PAYMENT_CREATE: 'PAYMENT_CREATE',
  CONTRACT_SIGN: 'CONTRACT_SIGN',
} as const;

export type CompanyAppMetadataCompanyForm = typeof CompanyAppMetadataCompanyForm[keyof typeof CompanyAppMetadataCompanyForm];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyAppMetadataCompanyForm = {
  NATURAL_PERSON: 'NATURAL_PERSON',
  JURIDICAL_PERSON: 'JURIDICAL_PERSON',
} as const;

export type CompanyAppMetadataApprovalState = typeof CompanyAppMetadataApprovalState[keyof typeof CompanyAppMetadataApprovalState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyAppMetadataApprovalState = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  BLOCKED: 'BLOCKED',
} as const;

export interface CompanyAppMetadata {
  approvalState: CompanyAppMetadataApprovalState;
  companyAddress?: string;
  companyContactEmail?: string;
  companyContactName?: string;
  companyContactPhoneCountry?: string;
  companyContactPhoneNumber?: string;
  companyContactPosition?: string;
  companyForm?: CompanyAppMetadataCompanyForm;
  companyId?: number;
  companyName?: string;
  companyNumber?: string;
  companyVat?: string;
  countryCode?: string;
  grants: CompanyAppMetadataGrantsItem[];
  plan: CompanyPlanAppMetadata;
  role: CompanyAppMetadataRole;
  users: CompanyUserAppMetadata[];
}

export interface CompanyMetadataRequest {
  companyNumber: string;
  countryCode: string;
}

export interface ErrorResponse {
  errors?: string[];
  message?: string;
  status: string;
  timestamp: number;
}

export interface PhoneTo {
  countryCode: string;
  phoneNumber: string;
}

export type CompanyDetailToForm = typeof CompanyDetailToForm[keyof typeof CompanyDetailToForm];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyDetailToForm = {
  NATURAL_PERSON: 'NATURAL_PERSON',
  JURIDICAL_PERSON: 'JURIDICAL_PERSON',
} as const;

export interface CompanyDetailTo {
  address?: string;
  contactEmail?: string;
  contactName?: string;
  contactPhone?: PhoneTo;
  contactPosition?: string;
  form?: CompanyDetailToForm;
  vat?: string;
}

export type CompanyPutRequestCompanyForm = typeof CompanyPutRequestCompanyForm[keyof typeof CompanyPutRequestCompanyForm];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyPutRequestCompanyForm = {
  NATURAL_PERSON: 'NATURAL_PERSON',
  JURIDICAL_PERSON: 'JURIDICAL_PERSON',
} as const;

export interface CompanyPutRequest {
  address: string;
  companyForm: CompanyPutRequestCompanyForm;
  contactEmail: string;
  contactName: string;
  contactPhone: PhoneUpdateRequest;
  contactPosition: string;
  vat: string;
}

export type SubscriptionToStatus = typeof SubscriptionToStatus[keyof typeof SubscriptionToStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionToStatus = {
  INCOMPLETE: 'INCOMPLETE',
  ACTIVE: 'ACTIVE',
  PAST_DUE: 'PAST_DUE',
  CANCELLED: 'CANCELLED',
  EXPIRED: 'EXPIRED',
  DELETED: 'DELETED',
} as const;

export type SubscriptionToSource = typeof SubscriptionToSource[keyof typeof SubscriptionToSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionToSource = {
  TRIAL: 'TRIAL',
  DEFAULT: 'DEFAULT',
  MANUAL: 'MANUAL',
  PAID: 'PAID',
} as const;

export type SubscriptionPaymentToStatus = typeof SubscriptionPaymentToStatus[keyof typeof SubscriptionPaymentToStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionPaymentToStatus = {
  INITIALIZED: 'INITIALIZED',
  WAITING: 'WAITING',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
  CANCELLED: 'CANCELLED',
  REFUNDED: 'REFUNDED',
} as const;

export type SubscriptionPaymentToGateway = typeof SubscriptionPaymentToGateway[keyof typeof SubscriptionPaymentToGateway];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionPaymentToGateway = {
  STRIPE: 'STRIPE',
  BANK: 'BANK',
} as const;

export interface SubscriptionPaymentTo {
  createdAt: string;
  gateway: SubscriptionPaymentToGateway;
  id: number;
  status: SubscriptionPaymentToStatus;
}

export type PlanToType = typeof PlanToType[keyof typeof PlanToType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanToType = {
  BASIC: 'BASIC',
  PLUS: 'PLUS',
  PREMIUM: 'PREMIUM',
} as const;

export interface PlanTo {
  country: string;
  features: PlanFeatureTo[];
  id: number;
  name: string;
  periods: PlanPeriodTo[];
  type: PlanToType;
}

export interface SubscriptionTo {
  companyId?: number;
  createdAt: string;
  expiresAt?: string;
  id: number;
  isActive: boolean;
  isCancellable: boolean;
  isRenewable: boolean;
  lastCompletedPayment?: SubscriptionPaymentTo;
  lastPayment?: SubscriptionPaymentTo;
  nexPaymentAt?: string;
  period?: PlanPeriodTo;
  plan: PlanTo;
  source: SubscriptionToSource;
  status: SubscriptionToStatus;
  statusUpdatedAt: string;
}

export type PlanPeriodToType = typeof PlanPeriodToType[keyof typeof PlanPeriodToType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanPeriodToType = {
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  YEARLY: 'YEARLY',
} as const;

export type PlanFeatureToMode = typeof PlanFeatureToMode[keyof typeof PlanFeatureToMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanFeatureToMode = {
  TRIAL: 'TRIAL',
  FULL: 'FULL',
} as const;

export interface PlanFeatureTo {
  feature: FeatureTo;
  menu?: boolean;
  mode: PlanFeatureToMode;
  plan?: boolean;
}

export type FeatureToType = typeof FeatureToType[keyof typeof FeatureToType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeatureToType = {
  MARKET_NEWS: 'MARKET_NEWS',
  STOCK_EXCHANGE: 'STOCK_EXCHANGE',
  QUICK_TRADING: 'QUICK_TRADING',
  PURCHASE_PRICES: 'PURCHASE_PRICES',
  TRADING: 'TRADING',
  TRANSACTIONS_ORDERS: 'TRANSACTIONS_ORDERS',
  STORAGES: 'STORAGES',
} as const;

export interface FeatureTo {
  icon: string;
  modes: FeatureToModes;
  type: FeatureToType;
}

export interface FeatureModeTo {
  description: string;
  title: string;
}

export type FeatureToModes = {[key: string]: FeatureModeTo};

export type CurrencyToType = typeof CurrencyToType[keyof typeof CurrencyToType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CurrencyToType = {
  CZK: 'CZK',
  EUR: 'EUR',
  GBP: 'GBP',
  RON: 'RON',
  USD: 'USD',
} as const;

export interface CurrencyTo {
  precision: number;
  type: CurrencyToType;
}

export interface PlanPeriodTo {
  currency: CurrencyTo;
  id: number;
  planId: number;
  price: number;
  type: PlanPeriodToType;
}

export type UserUserMetadata = {[key: string]: { [key: string]: unknown }};

export type UserAppMetadata = {[key: string]: { [key: string]: unknown }};

export interface ProfileData {
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  given_name?: string;
  name?: string;
  phone_number?: string;
  phone_verified?: boolean;
  username?: string;
}

export interface Identity {
  access_token?: string;
  connection?: string;
  isSocial?: boolean;
  profileData?: ProfileData;
  provider?: string;
  user_id?: string;
}

export interface User {
  app_metadata?: UserAppMetadata;
  blocked?: boolean;
  client_id?: string;
  connection?: string;
  created_at?: string;
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  given_name?: string;
  identities?: Identity[];
  last_ip?: string;
  last_login?: string;
  last_password_reset?: string;
  logins_count?: number;
  multifactor?: string[];
  name?: string;
  nickname?: string;
  /** @deprecated */
  password?: string[];
  phone_number?: string;
  phone_verified?: boolean;
  picture?: string;
  updated_at?: string;
  user_id?: string;
  user_metadata?: UserUserMetadata;
  username?: string;
  verify_email?: boolean;
  verify_password?: boolean;
  verify_phone_number?: boolean;
}

export interface UserUpdateCompanyRequest {
  companyNumber: string;
  countryCode: string;
}

export interface PhoneUpdateRequest {
  countryCode: string;
  phoneNumber: string;
}

export interface UserUpdateRequest {
  company?: UserUpdateCompanyRequest;
  /**
   * @minLength 2
   * @maxLength 2
   */
  language: string;
  name: string;
  phone: PhoneUpdateRequest;
}




type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  /**
 * @summary List of all users [users:user:list or users:*:list]
 */
export const getUsers = (
    params?: GetUsersParams,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<UserPage>(
      {url: `/can-users/api/user`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Update user profile information
 */
export const update = (
    userUpdateRequest: UserUpdateRequest,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<User>(
      {url: `/can-users/api/user`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: userUpdateRequest
    },
      options);
    }
  
/**
 * @summary Patch user profile information
 */
export const patch = (
    userPatchRequest: UserPatchRequest,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<User>(
      {url: `/can-users/api/user`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: userPatchRequest
    },
      options);
    }
  
/**
 * @summary Block user from logging in [users:user:block]
 */
export const block = (
    userId: string,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<void>(
      {url: `/can-users/api/user/${userId}/block`, method: 'PUT'
    },
      options);
    }
  
/**
 * @summary Unblock user [users:user:block]
 */
export const unblock = (
    userId: string,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<void>(
      {url: `/can-users/api/user/${userId}/block`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Cancel paid subscription
 */
export const cancelSubscription = (
    subscriptionId: number,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<SubscriptionTo>(
      {url: `/can-users/api/subscription/${subscriptionId}/cancel`, method: 'PUT'
    },
      options);
    }
  
/**
 * @summary Manually confirm payment [users:payment:confirm]
 */
export const confirmPayment = (
    paymentId: number,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<void>(
      {url: `/can-users/api/payment/${paymentId}/confirm`, method: 'PUT'
    },
      options);
    }
  
/**
 * @summary Manually cancell payment [users:payment:confirm]
 */
export const cancelPayment = (
    paymentId: number,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<void>(
      {url: `/can-users/api/payment/${paymentId}/cancel`, method: 'PUT'
    },
      options);
    }
  
/**
 * @summary Get company detail information by id
 */
export const getCompanyDetail = (
    companyId: number,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CompanyDetailTo>(
      {url: `/can-users/api/company/${companyId}/detail`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Update company detail information by id
 */
export const updateCompanyDetail = (
    companyId: number,
    companyPutRequest: CompanyPutRequest,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CompanyDetailTo>(
      {url: `/can-users/api/company/${companyId}/detail`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: companyPutRequest
    },
      options);
    }
  
/**
 * @summary Update company detail information by id
 */
export const patchCompanyDetail = (
    companyId: number,
    companyPatchRequest: CompanyPatchRequest,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CompanyDetailTo>(
      {url: `/can-users/api/company/${companyId}/detail`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: companyPatchRequest
    },
      options);
    }
  
/**
 * @deprecated
 * @summary Set user approval state in a company [users:company:approve]
 */
export const setUserStateDeprecated = (
    userId: number,
    state: 'WAITING' | 'APPROVED' | 'BLOCKED',
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<void>(
      {url: `/can-users/api/company/user/${userId}/${state}`, method: 'PUT'
    },
      options);
    }
  
/**
 * @summary Set user approval state in a company [users:company:approve]
 */
export const setUserState = (
    userCompanyId: number,
    state: 'WAITING' | 'APPROVED' | 'BLOCKED',
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<void>(
      {url: `/can-users/api/company/user/${userCompanyId}/state/${state}`, method: 'PUT'
    },
      options);
    }
  
/**
 * @summary Set user role in a company [users:company:role]
 */
export const setUserRole = (
    userCompanyId: number,
    role: 'USER' | 'STATUTORY',
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<void>(
      {url: `/can-users/api/company/user/${userCompanyId}/role/${role}`, method: 'PUT'
    },
      options);
    }
  
/**
 * @summary Handle payment status from gateway
 */
export const create = (
    createBody: string,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<void>(
      {url: `/can-users/public/api/payment`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createBody
    },
      options);
    }
  
/**
 * @summary Returns internal company metadata for multiple companies
 */
export const getList = (
    user: string,
    companyMetadataRequest: CompanyMetadataRequest[],
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CompanyAppMetadata[]>(
      {url: `/can-users/internal/api/company/${user}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: companyMetadataRequest
    },
      options);
    }
  
/**
 * @summary Create a new paid subscription
 */
export const createPaid = (
    subscriptionCreatePaidRequest: SubscriptionCreatePaidRequest,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<PaymentTo>(
      {url: `/can-users/api/subscription/paid`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: subscriptionCreatePaidRequest
    },
      options);
    }
  
/**
 * @summary Create a new manual subscription [users:company:plan]
 */
export const createManual = (
    subscriptionCreateManualRequest: SubscriptionCreateManualRequest,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<SubscriptionTo>(
      {url: `/can-users/api/subscription/manual`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: subscriptionCreateManualRequest
    },
      options);
    }
  
/**
 * @summary Validate company identification number in country specific registry
 */
export const validate = (
    companyValidationRequest: CompanyValidationRequest,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CompanyValidationResponse>(
      {url: `/can-users/api/company/validate`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: companyValidationRequest
    },
      options);
    }
  
/**
 * @summary List of all available languages
 */
export const getLanguages = (
    
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<LanguageTo[]>(
      {url: `/can-users/public/api/language`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Language detail
 */
export const getLanguage = (
    id: string,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<LanguageTo>(
      {url: `/can-users/public/api/language/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Get all users
 */
export const getAll = (
    
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<User[]>(
      {url: `/can-users/internal/api/user`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Get user by email
 */
export const getByEmail = (
    email: string,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<UserTo[]>(
      {url: `/can-users/internal/api/user/email/${email}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Get default feature
 */
export const getInternalDefaultFeature = (
    feature: 'MARKET_NEWS' | 'STOCK_EXCHANGE' | 'QUICK_TRADING' | 'PURCHASE_PRICES' | 'TRADING' | 'TRANSACTIONS_ORDERS' | 'STORAGES',
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CompanyFeatureModeTo>(
      {url: `/can-users/internal/api/plan/feature/${feature}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary List of all available features for provided company
 */
export const getInternalCompanyFeatures = (
    companyId: number,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<GetInternalCompanyFeatures200>(
      {url: `/can-users/internal/api/plan/company/${companyId}/feature`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Get feature information for provided company
 */
export const getInternalCompanyFeature = (
    companyId: number,
    feature: 'MARKET_NEWS' | 'STOCK_EXCHANGE' | 'QUICK_TRADING' | 'PURCHASE_PRICES' | 'TRADING' | 'TRANSACTIONS_ORDERS' | 'STORAGES',
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CompanyFeatureModeTo>(
      {url: `/can-users/internal/api/plan/company/${companyId}/feature/${feature}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Get all users
 */
export const getAll1 = (
    
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<FeatureTo[]>(
      {url: `/can-users/internal/api/feature`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Returns internal company metadata for provided company number
 */
export const get = (
    country: string,
    company: string,
    user: string,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CompanyAppMetadata>(
      {url: `/can-users/internal/api/company/${country}/${company}/${user}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Get user detail [users:user:list or users:*:list]
 */
export const getUser = (
    userId: string,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<UserTo>(
      {url: `/can-users/api/user/${userId}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Permanently delete user and their company relations [users:user:delete]
 */
export const _delete = (
    userId: string,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<void>(
      {url: `/can-users/api/user/${userId}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Get subscription detail
 */
export const getSubscription = (
    subscriptionId: number,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<SubscriptionTo>(
      {url: `/can-users/api/subscription/${subscriptionId}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Delete manual subscription [users:company:plan]
 */
export const deleteSubscription = (
    subscriptionId: number,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<number>(
      {url: `/can-users/api/subscription/${subscriptionId}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Get plan by provided ID
 */
export const getPlan = (
    planId: number,
    params?: GetPlanParams,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<PlanTo>(
      {url: `/can-users/api/plan/${planId}`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary List of all available default features
 */
export const getDefaultFeatures = (
    
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<GetDefaultFeatures200>(
      {url: `/can-users/api/plan/feature`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary List of all available plans for provided country
 */
export const getCountryPlans = (
    countryId: string,
    params?: GetCountryPlansParams,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<PlanTo[]>(
      {url: `/can-users/api/plan/country/${countryId}`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary List of all available plans for provided company
 */
export const getCompanyPlans = (
    params?: GetCompanyPlansParams,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<PlanTo[]>(
      {url: `/can-users/api/plan/company`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary List of all available features for provided company
 */
export const getCompanyFeatures = (
    
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<GetCompanyFeatures200>(
      {url: `/can-users/api/plan/company/feature`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary List of all payments [users:payment:list or users:*:list]
 */
export const getPayments = (
    params?: GetPaymentsParams,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<PaymentPage>(
      {url: `/can-users/api/payment`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Get payment by ID
 */
export const getPayment = (
    paymentId: number,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<PaymentTo>(
      {url: `/can-users/api/payment/${paymentId}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Get payment status
 */
export const status = (
    gatewayId: string,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<PaymentTo>(
      {url: `/can-users/api/payment/status/${gatewayId}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary List of all payments for provided company
 */
export const getCompanyPayments = (
    params?: GetCompanyPaymentsParams,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<PaymentPage>(
      {url: `/can-users/api/payment/company`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Get invoice in QR format
 */
export const getQR = (
    invoiceId: number,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<string>(
      {url: `/can-users/api/invoice/${invoiceId}/qr`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Get invoice in PDF format
 */
export const getInvoice = (
    invoiceId: number,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<string>(
      {url: `/can-users/api/invoice/${invoiceId}/pdf`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary List of all available countries
 */
export const getCountries = (
    
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CountryTo[]>(
      {url: `/can-users/api/country`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Country detail
 */
export const getCountry = (
    id: string,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CountryTo>(
      {url: `/can-users/api/country/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary List of all companies [users:company:list or users:*:list]
 */
export const getCompanies = (
    params?: GetCompaniesParams,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CompanyPage>(
      {url: `/can-users/api/company`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Get company by id
 */
export const getCompany = (
    companyId: number,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CompanyTo>(
      {url: `/can-users/api/company/${companyId}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary List of all company users [users:company:list or users:*:list]
 */
export const getCompanyUsers = (
    companyId: number,
    params?: GetCompanyUsersParams,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CompanySimpleUserTo[]>(
      {url: `/can-users/api/company/${companyId}/user`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary List of all users with company relation [users:company:list or users:*:list]
 */
export const getUsers1 = (
    params?: GetUsers1Params,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CompanyUserPage>(
      {url: `/can-users/api/company/user`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Get user company relation [users:company:list or users:*:list]
 */
export const getUser1 = (
    userCompanyId: number,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CompanyUserTo>(
      {url: `/can-users/api/company/user/${userCompanyId}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Get user company relation [users:company:delete]
 */
export const deleteUser = (
    userCompanyId: number,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<void>(
      {url: `/can-users/api/company/user/${userCompanyId}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Export all users with company relation to excel [users:company:list or users:*:list]
 */
export const exportUsers = (
    params?: ExportUsersParams,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<StreamingResponseBody>(
      {url: `/can-users/api/company/user/export`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Get company in current context
 */
export const getCurrentCompany = (
    
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CompanyTo>(
      {url: `/can-users/api/company/current`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Get current company detail information
 */
export const getCurrentCompanyDetail = (
    
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<CompanyDetailTo>(
      {url: `/can-users/api/company/current/detail`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Permanently deletes testing users
 */
export const deleteTestUsers = (
    
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<void>(
      {url: `/can-users/e2e/api/user`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Permanently deletes company subscription
 */
export const deleteTestCompany = (
    companyNumber: string,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<void>(
      {url: `/can-users/e2e/api/subscription/company/${companyNumber}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Permanently deletes company and all its users
 */
export const deleteTestCompany1 = (
    companyNumber: string,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<void>(
      {url: `/can-users/e2e/api/company/${companyNumber}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Reset user and their company relations [users:user:reset]
 */
export const reset = (
    userId: string,
 options?: SecondParameter<typeof usersInstance>,) => {
      return usersInstance<void>(
      {url: `/can-users/api/user/${userId}/reset`, method: 'DELETE'
    },
      options);
    }
  
export type GetUsersResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>
export type UpdateResult = NonNullable<Awaited<ReturnType<typeof update>>>
export type PatchResult = NonNullable<Awaited<ReturnType<typeof patch>>>
export type BlockResult = NonNullable<Awaited<ReturnType<typeof block>>>
export type UnblockResult = NonNullable<Awaited<ReturnType<typeof unblock>>>
export type CancelSubscriptionResult = NonNullable<Awaited<ReturnType<typeof cancelSubscription>>>
export type ConfirmPaymentResult = NonNullable<Awaited<ReturnType<typeof confirmPayment>>>
export type CancelPaymentResult = NonNullable<Awaited<ReturnType<typeof cancelPayment>>>
export type GetCompanyDetailResult = NonNullable<Awaited<ReturnType<typeof getCompanyDetail>>>
export type UpdateCompanyDetailResult = NonNullable<Awaited<ReturnType<typeof updateCompanyDetail>>>
export type PatchCompanyDetailResult = NonNullable<Awaited<ReturnType<typeof patchCompanyDetail>>>
export type SetUserStateDeprecatedResult = NonNullable<Awaited<ReturnType<typeof setUserStateDeprecated>>>
export type SetUserStateResult = NonNullable<Awaited<ReturnType<typeof setUserState>>>
export type SetUserRoleResult = NonNullable<Awaited<ReturnType<typeof setUserRole>>>
export type CreateResult = NonNullable<Awaited<ReturnType<typeof create>>>
export type GetListResult = NonNullable<Awaited<ReturnType<typeof getList>>>
export type CreatePaidResult = NonNullable<Awaited<ReturnType<typeof createPaid>>>
export type CreateManualResult = NonNullable<Awaited<ReturnType<typeof createManual>>>
export type ValidateResult = NonNullable<Awaited<ReturnType<typeof validate>>>
export type GetLanguagesResult = NonNullable<Awaited<ReturnType<typeof getLanguages>>>
export type GetLanguageResult = NonNullable<Awaited<ReturnType<typeof getLanguage>>>
export type GetAllResult = NonNullable<Awaited<ReturnType<typeof getAll>>>
export type GetByEmailResult = NonNullable<Awaited<ReturnType<typeof getByEmail>>>
export type GetInternalDefaultFeatureResult = NonNullable<Awaited<ReturnType<typeof getInternalDefaultFeature>>>
export type GetInternalCompanyFeaturesResult = NonNullable<Awaited<ReturnType<typeof getInternalCompanyFeatures>>>
export type GetInternalCompanyFeatureResult = NonNullable<Awaited<ReturnType<typeof getInternalCompanyFeature>>>
export type GetAll1Result = NonNullable<Awaited<ReturnType<typeof getAll1>>>
export type GetResult = NonNullable<Awaited<ReturnType<typeof get>>>
export type GetUserResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type _DeleteResult = NonNullable<Awaited<ReturnType<typeof _delete>>>
export type GetSubscriptionResult = NonNullable<Awaited<ReturnType<typeof getSubscription>>>
export type DeleteSubscriptionResult = NonNullable<Awaited<ReturnType<typeof deleteSubscription>>>
export type GetPlanResult = NonNullable<Awaited<ReturnType<typeof getPlan>>>
export type GetDefaultFeaturesResult = NonNullable<Awaited<ReturnType<typeof getDefaultFeatures>>>
export type GetCountryPlansResult = NonNullable<Awaited<ReturnType<typeof getCountryPlans>>>
export type GetCompanyPlansResult = NonNullable<Awaited<ReturnType<typeof getCompanyPlans>>>
export type GetCompanyFeaturesResult = NonNullable<Awaited<ReturnType<typeof getCompanyFeatures>>>
export type GetPaymentsResult = NonNullable<Awaited<ReturnType<typeof getPayments>>>
export type GetPaymentResult = NonNullable<Awaited<ReturnType<typeof getPayment>>>
export type StatusResult = NonNullable<Awaited<ReturnType<typeof status>>>
export type GetCompanyPaymentsResult = NonNullable<Awaited<ReturnType<typeof getCompanyPayments>>>
export type GetQRResult = NonNullable<Awaited<ReturnType<typeof getQR>>>
export type GetInvoiceResult = NonNullable<Awaited<ReturnType<typeof getInvoice>>>
export type GetCountriesResult = NonNullable<Awaited<ReturnType<typeof getCountries>>>
export type GetCountryResult = NonNullable<Awaited<ReturnType<typeof getCountry>>>
export type GetCompaniesResult = NonNullable<Awaited<ReturnType<typeof getCompanies>>>
export type GetCompanyResult = NonNullable<Awaited<ReturnType<typeof getCompany>>>
export type GetCompanyUsersResult = NonNullable<Awaited<ReturnType<typeof getCompanyUsers>>>
export type GetUsers1Result = NonNullable<Awaited<ReturnType<typeof getUsers1>>>
export type GetUser1Result = NonNullable<Awaited<ReturnType<typeof getUser1>>>
export type DeleteUserResult = NonNullable<Awaited<ReturnType<typeof deleteUser>>>
export type ExportUsersResult = NonNullable<Awaited<ReturnType<typeof exportUsers>>>
export type GetCurrentCompanyResult = NonNullable<Awaited<ReturnType<typeof getCurrentCompany>>>
export type GetCurrentCompanyDetailResult = NonNullable<Awaited<ReturnType<typeof getCurrentCompanyDetail>>>
export type DeleteTestUsersResult = NonNullable<Awaited<ReturnType<typeof deleteTestUsers>>>
export type DeleteTestCompanyResult = NonNullable<Awaited<ReturnType<typeof deleteTestCompany>>>
export type DeleteTestCompany1Result = NonNullable<Awaited<ReturnType<typeof deleteTestCompany1>>>
export type ResetResult = NonNullable<Awaited<ReturnType<typeof reset>>>

import React from "react";

import { Spacer } from "@/common/components/spacer/spacer";

const FullscreenModalLayout = ({
  children,
  headerButton,
}: React.PropsWithChildren<{ headerButton: React.ReactNode }>) => {
  return (
    <article
      style={{ gridRow: 1, gridColumn: 1 }}
      className="shrink-1 absolute inset-0 z-[1] flex flex-grow flex-col overflow-y-auto bg-white px-6 lg:static lg:-z-[0]"
    >
      <span className="mt-9 self-start">{headerButton}</span>
      {children}
    </article>
  );
};

const Title = ({ children }: React.PropsWithChildren) => (
  <>
    <Spacer className="h-6" />
    <h1 className="text-2xl font-black text-can-forest-teal">{children}</h1>
    <Spacer className="h-6" />
  </>
);

FullscreenModalLayout.Title = Title;

export { FullscreenModalLayout };

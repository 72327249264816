import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DateTime } from "luxon";
import { useId } from "react";
import { generatePath, Outlet, useSearchParams } from "react-router-dom";

import { Failed, NoData } from "@/common/components/info/info";
import { Metadata } from "@/common/components/metadata/metadata";
import {
  itemsPerPage,
  Pagination,
} from "@/common/components/pagination/pagination";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { Spacer } from "@/common/components/spacer/spacer";
import { Spinner } from "@/common/components/spinner/spinner";
import { UserIdentity } from "@/common/components/user-identity/user-identity";
import { countryCodeToFlag } from "@/common/utils/unicode";
import { UserTo } from "@/generated/api/users";
import { AutocompleteString } from "@/types";

import { path as companyPath } from "../company/company";
import { Filter } from "../components/table/filter/filter";
import { Table } from "../components/table/table";
import { useExportUsersMutation, usersQuery } from "./users.api";

const path = "users";

const columnHelper = createColumnHelper<UserTo>();
const columns = [
  columnHelper.accessor("identities", {}),
  columnHelper.accessor("id", {}),
  columnHelper.accessor("name", {
    header: () => t`Jméno`,
    cell: (info) => {
      return (
        <SearchParamsLink
          className="flex gap-2 text-can-forest-teal"
          to={info.row.getValue("id")}
        >
          <UserIdentity identity={info.row.getValue("identities")} />
          {info.getValue()}
        </SearchParamsLink>
      );
    },
  }),
  columnHelper.accessor("company", {
    header: () => t`Společnosti`,
    cell: (info) => (
      <>
        {info.getValue().map((company) => (
          <SearchParamsLink
            className="block text-can-forest-teal"
            key={company.companyId}
            to={generatePath(companyPath, { id: company.companyId.toString() })}
          >
            {company.companyCountry ? (
              <>{countryCodeToFlag(company.companyCountry)} </>
            ) : null}
            {company.companyName}
          </SearchParamsLink>
        ))}
      </>
    ),
  }),
  columnHelper.accessor("email", {
    header: () => t`E-mail`,
    meta: {
      filterVariant: "text",
      filterKey: "search",
      label: () => t`E-mail`,
    },
  }),
  columnHelper.accessor("phone", {
    header: () => t`Telefon`,
    cell: (info) =>
      info.getValue()
        ? info.getValue()?.countryCode + "" + info.getValue()?.phoneNumber
        : "",
  }),
  columnHelper.accessor("lastLogin", {
    header: () => t`Poslední přihlášení`,
    cell: (info) => {
      const value = info.getValue();
      if (!value) {
        return "-";
      }
      return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
    },
  }),
  columnHelper.accessor("createdAt", {
    header: () => t`Datum registrace`,
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_SHORT),
  }),
];

const fallbackTable: UserTo[] = [];

const Users = () => {
  const titleKey = useId();
  const { params, handleParamChange } = useUsersParams();
  const search = params.search.length < 3 ? "" : params.search;
  const exportUsersMutation = useExportUsersMutation();
  const users = useQuery({
    placeholderData: keepPreviousData,
    ...usersQuery({
      search,
      page: params.page,
    }),
  });
  const table = useReactTable({
    data: users.data?.data ?? fallbackTable,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualFiltering: true,
    manualPagination: true,
    state: {
      columnVisibility: {
        identities: false,
        id: false,
      },
    },
  });

  const handleExport = () => {
    exportUsersMutation.mutate({
      search,
    });
  };

  if (users.status === "pending" && users.isInitialLoading) {
    return (
      <>
        <Metadata title={t`Správa uživatelů`} />
        <Spinner className="mt-4" />
      </>
    );
  }

  if (users.status === "error") {
    return <Failed error={users.error} />;
  }

  return (
    <>
      <Metadata key={titleKey} title={t`Správa uživatelů`} />
      <div className="flex w-full">
        <label className="mx-4 flex items-baseline gap-x-4">
          <Trans>
            Hledat e-mail nebo jméno uživatele (zadejte alespoň tři znaky)
          </Trans>
          <Filter
            onFilterChange={handleParamChange}
            value={params.search}
            column={table.getColumn("email")!}
          />
        </label>
        <button
          disabled={exportUsersMutation.status === "pending"}
          onClick={handleExport}
          className="flex items-center gap-2 pt-2 text-can-forest-teal"
        >
          <Trans>Exportovat</Trans>
          {exportUsersMutation.status === "pending" ? (
            <Spinner className="h-3 w-3" withHint={false} />
          ) : null}
        </button>
      </div>
      <Spacer className="h-4" />

      <Table>
        <Table.Head table={table} />
        {users.status === "success" && users.data.data.length > 0 ? (
          <Table.Body table={table} />
        ) : null}
      </Table>
      {users.data ? (
        <>
          <Spacer className="h-5" />
          <Pagination
            getter={() => parseInt(params.page, 10)}
            setter={(page) => handleParamChange("page", page.toString())}
            pageSize={itemsPerPage.oneHundred}
            total={users.data.totalSize}
          />
        </>
      ) : null}
      {users.status === "success" &&
      users.fetchStatus === "idle" &&
      users.data?.data.length === 0 ? (
        <div className="mt-4">
          <NoData>
            <Trans>Nebyly nalezeny žádné záznamy</Trans>
          </NoData>
        </div>
      ) : null}
      {users.fetchStatus === "fetching" && !users.isInitialLoading ? (
        <Spinner withHint={false} />
      ) : null}
      <Outlet />
    </>
  );
};

const useUsersParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = {
    page: searchParams.get("page") ?? "1",
    search: searchParams.get("search") ?? "",
  };

  const handleParamChange = (
    key: AutocompleteString<"page" | "search">,
    value: string,
  ) => {
    setSearchParams((c) => {
      c.set(key, value);
      return c;
    });
  };

  return { params, handleParamChange };
};

export { path, Users };

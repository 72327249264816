/* eslint-disable lingui/no-unlocalized-strings */
import { Strategy } from "../reports.flow";

class TrialStrategy implements Strategy {
  public isMasked(params: { isStub: boolean }) {
    return params.isStub;
  }

  public getDummyReport(): ReturnType<Strategy["getDummyReport"]> {
    return {
      commodities: Array.from({ length: 4 }).map((_, i) => ({
        summary:
          "Lorem ipsum odor amet, consectetuer adipiscing elit. Pellentesque magna venenatis; vitae sem eget imperdiet.",
        headline: "Lorem ipsum odor amet, consectetuer adipiscing elit.",
        commodity: {
          id: i,
          icon: "",
          name: "Lorem",
        },
        recommendation: "BULLISH",
        recommendationText:
          "Lorem ipsum odor amet, consectetuer adipiscing elit.",
      })),
    };
  }
}

export { TrialStrategy };

import { useAuth0 } from "@auth0/auth0-react";
import { useQuery, useSuspenseQueries } from "@tanstack/react-query";
import React from "react";
import invariant from "tiny-invariant";

import { QuickFilters } from "@/common/components/quick-filters/quick-filters";
import { getUser } from "@/common/services/user";
import {
  CommodityRatesToCommodity,
  CurrencyRatesToCurrency1,
  GetCommodityRatesPeriod,
} from "@/generated/api/prices";

import { commodityQuery, currencyPairQuery } from "./stock-exchange.api";
import {
  useActiveFilter,
  useFilterClickHandler,
  useFilters,
} from "./stock-exchange.hooks";
import { getFilters } from "./stock-exchange.utils";

type CommoditySettledProvider = {
  data: ReturnType<
    Exclude<ReturnType<typeof commodityQuery>["select"], undefined>
  >[];
  status: "success" | "empty";
};

type BusyProvider<TData> = {
  currency: undefined;
  data: TData;
  status: "error" | "pending";
};

type CommodityProviderResult = CommoditySettledProvider & ProviderProps;
type CurrencyProviderResult = (
  | CurrencySettledProvider
  | BusyProvider<undefined>
) &
  ProviderProps;

type ProviderProps = {
  filters: React.ComponentProps<typeof QuickFilters>;
};

type CommodityProviderProps = {
  children: (result: CommodityProviderResult) => React.ReactNode;
  commodityId: CommodityRatesToCommodity;
  currency: CurrencyRatesToCurrency1;
  periods: readonly GetCommodityRatesPeriod[];
};

type CurrencySettledProvider = {
  data: ReturnType<
    Exclude<ReturnType<typeof currencyPairQuery>["select"], undefined>
  >;
  currency: CurrencyRatesToCurrency1;
  status: "success" | "empty";
};

const CurrencyProvider = ({
  children,
}: {
  children: (result: CurrencyProviderResult) => React.ReactNode;
}) => {
  const filtersWithoutToday = getFilters().filter(
    (filter) => filter.value !== "today",
  );
  const handleFilterClick = useFilterClickHandler(filtersWithoutToday);
  const activeFilter = useActiveFilter("currencyPair");
  const currencyPair = useDetectCurrency();
  const query = useQuery(
    currencyPairQuery({
      ...currencyPair,
      range: activeFilter.value,
    }),
  );

  if (query.status === "success") {
    return children({
      data: query.data,
      status: query.data.rates.length < 2 ? "empty" : query.status,
      currency: currencyPair.currency2,
      filters: {
        onClick: (i: number) => handleFilterClick("currencyPair", i),
        activeFilter,
        filters: filtersWithoutToday,
      },
    });
  }

  return children({
    currency: undefined,
    data: undefined,
    status: query.status,
    filters: {
      onClick: (i: number) => handleFilterClick("currencyPair", i),
      activeFilter,
      filters: filtersWithoutToday,
    },
  });
};

const CommodityProvider = ({
  currency,
  commodityId,
  periods,
  children,
}: CommodityProviderProps) => {
  const filters = useFilters({ commodityId });
  const queries = useSuspenseQueries({
    queries: periods.map((period) =>
      commodityQuery({
        commodity: commodityId,
        currency,
        period,
        range: filters.activeFilter.value,
      }),
    ),
  });

  const getStatus = () => {
    if (queries.every((q) => q.data?.rates && q.data.rates.length < 2)) {
      return "empty";
    }

    return "success";
  };

  return children({
    data: queries.map((q) => q.data),
    status: getStatus(),
    currency,
    filters,
  } as CommodityProviderResult);
};

const Filters = ({
  commodityId,
  children,
}: {
  commodityId: CommodityRatesToCommodity;
  children: (filters: ReturnType<typeof useFilters>) => React.ReactNode;
}) => {
  const filters = useFilters({ commodityId });
  return children(filters);
};

const useDetectCurrency = (): {
  currency1: CurrencyRatesToCurrency1;
  currency2: CurrencyRatesToCurrency1;
} => {
  const { user: _user } = useAuth0();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(_user, "_user is not defined");
  const user = getUser(_user);

  if (
    (user.user_metadata.can.companies ?? []).findIndex(
      (company) => company.countryCode === "CZ",
    ) > -1
  ) {
    return { currency1: "EUR", currency2: "CZK" };
  }

  return { currency1: "USD", currency2: "EUR" };
};

export { CommodityProvider, CurrencyProvider, Filters };

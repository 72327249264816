import { Trans } from "@lingui/react/macro";

import { Button } from "@/common/components/button/button";

import ShopSvg from "./assets/shop.svg?react";

const IWantToTradeDialog = ({ onClick }: { onClick: () => void }) => {
  return (
    <aside className="sticky bottom-4 flex flex-col gap-6 rounded-t-[24px] bg-white p-4 shadow-can-light-box  sm:flex-row sm:rounded-[24px]">
      <div className="self-center rounded-full bg-can-silver-gray p-8 sm:p-4">
        <ShopSvg className="h-12 w-12 sm:h-6 sm:w-6" />
      </div>
      <div className="flex flex-col items-center sm:flex-grow sm:items-start">
        <h3 className="font-bold text-can-midnight-steel">
          <Trans>Toto jsou závazné výkupní ceny.</Trans>
        </h3>
        <p className="text-center text-xs sm:text-left">
          <Trans>
            Ceny garantované obchodníky, kteří vkládají cenu do systému. Pokud
            chcete obchodovat, klikněte na tlačítko níže.
          </Trans>
        </p>
      </div>
      <Button
        onClick={onClick}
        className="basis-full disabled:cursor-wait sm:flex-shrink-0 sm:basis-[30%] sm:self-center"
        variant="primary"
      >
        <Trans>Chci obchodovat</Trans>
      </Button>
    </aside>
  );
};

export { IWantToTradeDialog };

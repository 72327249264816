import BooksSvg from "@icons/books.svg?react";
import ChartSvg from "@icons/chart.svg?react";
import GarageOpenSvg from "@icons/garage-open.svg?react";
import NewspaperSvg from "@icons/newspaper.svg?react";
import UsdCircleSvg from "@icons/usd-circle.svg?react";
import { t } from "@lingui/core/macro";
import { NavLink } from "react-router-dom";

import { useFeatureFlag } from "@/common/providers/feature-flag-provider";
import { path as purchasePricesPath } from "@/routes/purchase-prices/purchase-prices";
import { path as reportsPath } from "@/routes/reports/reports";
import { getAbsolutePath } from "@/routes/routes.utils";
import { path as stockExchangePath } from "@/routes/stock-exchange/stock-exchange";
import { path as storagesPath } from "@/routes/storages/storages";
import { path as tradesPath } from "@/routes/trades/trades";

type Link = {
  className?: string | ((args: { isActive: boolean }) => string);
  to: string;
  icon: React.ReactNode;
  label: string;
};

const NavBar = ({
  renderItem,
}: {
  renderItem?: (link: Link) => React.ReactNode;
}) => {
  const featureFlag = useFeatureFlag();
  const links: Link[] = [];

  if (featureFlag.get("trading")) {
    links.push({
      className: getClassName,
      label: t`Výkupní ceny`,
      to: getAbsolutePath(purchasePricesPath),
      icon: <UsdCircleSvg />,
    });
  }

  links.push(
    ...([
      {
        className: getClassName,
        label: t`Burzovní informace`,
        to: getAbsolutePath(stockExchangePath),
        icon: <ChartSvg />,
      },
      {
        className: getClassName,
        label: t`Zprávy`,
        to: getAbsolutePath(reportsPath),
        icon: <NewspaperSvg />,
      },
    ] satisfies Link[]),
  );

  if (featureFlag.get("premium") && featureFlag.get("trading")) {
    links.push(
      ...([
        {
          className: getClassName,
          label: t`Prodej komodit`,
          to: getAbsolutePath(tradesPath),
          icon: <UsdCircleSvg />,
        },
        {
          className: getClassName,
          label: t`Sklady`,
          to: getAbsolutePath(storagesPath),
          icon: <GarageOpenSvg />,
        },
        {
          className: getClassName,
          label: t`Transakce a příkazy`,
          to: getAbsolutePath("transactions-and-orders"),
          icon: <BooksSvg />,
        },
      ] satisfies Link[]),
    );
  }

  return (
    <nav className="flex w-full flex-grow flex-col items-center gap-3 font-bold text-white shadow-can-light-box">
      {links.map(({ label, icon, ...link }) =>
        typeof renderItem === "function" ? (
          renderItem({ ...link, icon, label })
        ) : (
          <NavLink title={label} key={link.to} {...link}>
            {icon}
          </NavLink>
        ),
      )}
    </nav>
  );
};

// eslint-disable-next-line lingui/no-unlocalized-strings
const active = "[&_path]:fill-white bg-[#399E82] text-white";
// eslint-disable-next-line lingui/no-unlocalized-strings
const inactive = "[&_path]:fill-[#85C4B3] text-[#85C4B3]";
const getClassName = ({ isActive }: { isActive: boolean }) =>
  // eslint-disable-next-line lingui/no-unlocalized-strings
  `${isActive ? active : inactive} flex w-full justify-center py-3`;

export { active, getClassName, NavBar };

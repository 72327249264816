import starSrc from "@icons/star.svg?url";
import starFilledSrc from "@icons/star-filled.svg?url";
import starOutlineSrc from "@icons/star-outline.svg?url";
import { msg, t } from "@lingui/core/macro";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/react/macro";
import { useQuery } from "@tanstack/react-query";
import chunk from "lodash-es/chunk";
import partition from "lodash-es/partition";
import { DateTime } from "luxon";
import React from "react";
import {
  createSearchParams,
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { z } from "zod";

import { CropIcon } from "@/common/components/crop-icon/crop-icon";
import { Failed } from "@/common/components/info/info";
import { Metadata } from "@/common/components/metadata/metadata";
import { PageTitle } from "@/common/components/page-title/page-title";
import {
  PriceItem,
  PriceList,
} from "@/common/components/price-list/price-list";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { Spacer } from "@/common/components/spacer/spacer";
import { Spinner } from "@/common/components/spinner/spinner";
import { WrapMeWith } from "@/common/components/wrap-me-with/wrap-me-with";
import { useCurrency } from "@/common/hooks/use-currency";
import { useLocalStorage } from "@/common/hooks/use-local-storage";
import { useScrollbarToggle } from "@/common/hooks/use-scrollbar-toggle";
import { useFeatureFlag } from "@/common/providers/feature-flag-provider";
import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";
import { toggleItem } from "@/common/utils/array";
import { getNextQuarter, isNextQuarter } from "@/common/utils/trading";
import { Currency } from "@/generated/digitalnisklady.cz/graphql";

import { TwoColumnLayout } from "../layout/layout";
import { postalCodesQuery } from "../shared/api/get-postal-codes";
import { PostalCodeDialog } from "../shared/components/postal-code-dialog/postal-code-dialog";
import { currencySchema } from "../shared/currency.params";
import { cropPricesQuery } from "./purchase-prices.api";

const path = "purchase-prices";

const PurchasePrices = () => {
  const { marketPriceId } = useParams();
  const isDetailOpen = marketPriceId !== undefined;
  const sidebar = useSidebar();
  const isRightPaneOpen = isDetailOpen || sidebar.state.screen;
  const flags = useFeatureFlag();
  const { _ } = useLingui();
  const { params, enforceDialog } = usePurchasePricesParams();
  useScrollbarToggle(params?.enforceDialog);
  const [CurrencySwitch, currency] = useCurrency();
  const postalCodes = useQuery(postalCodesQuery());
  const postalCodeObject = postalCodes.data?.find(
    (postalCode) => postalCode.code === params?.postalCode,
  );
  const [favorites, setFavorites] = useLocalStorage<string[]>(
    "purchase-prices.favorites",
    [],
  );
  const featureFlag = useFeatureFlag();

  const cropPrices = useQuery({
    refetchInterval: 60_000,
    ...cropPricesQuery({
      // @ts-expect-error this can hardly ever be undefined, yet I have no idea how to express it here
      longitude: postalCodeObject?.longitude,
      // @ts-expect-error this can hardly ever be undefined, yet I have no idea how to express it here
      latitude: postalCodeObject?.latitude,
      currency: currency as Currency,
    }),
    enabled: !!postalCodeObject,
  });
  const [favoriteCropPrices, otherCropPrices] = partition(
    cropPrices.data,
    (price) => favorites.includes(price.crop.name),
  );

  const toggleFavorite = (cropName: string) => {
    setFavorites((current) => toggleItem(current, cropName));
  };

  if (postalCodes.status === "success" && params?.postalCode) {
    if (
      !postalCodes.data.find(
        (postalCode) => postalCode.code === params?.postalCode,
      )
    ) {
      return <Navigate to="?" />;
    }
  }

  return (
    <TwoColumnLayout
      right={isDetailOpen}
      left={{
        header: (
          <>
            <PageTitle>
              {flags.get("premium") ? (
                <Trans>Rychlý prodej komodit</Trans>
              ) : (
                <Trans>Moje výkupní ceny</Trans>
              )}
            </PageTitle>
            {CurrencySwitch}
          </>
        ),
        content: (
          <div className="flex basis-full flex-col">
            <Metadata title={_(msg`Moje výkupní ceny`)} />
            <Spacer />
            <div className="flex flex-col items-start gap-4 rounded-2xl bg-[#EFF5F4] p-4 text-can-midnight-steel sm:flex-row sm:items-center">
              {postalCodeObject ? (
                <b>
                  <Trans>
                    Ceny pro {postalCodeObject.code}, {postalCodeObject.name}
                  </Trans>
                </b>
              ) : null}
              <small>
                <Trans>
                  Nyní zobrazujeme aktuální ceny komodit pro oblast dle
                  poštovního směrovacího čísla (PSČ)
                </Trans>
              </small>
              <button
                onClick={enforceDialog}
                className="ml-0 w-full flex-shrink rounded-lg border border-can-forest-teal px-4 py-2 text-xs text-can-forest-teal sm:ml-auto sm:max-w-[259px]"
              >
                <Trans>Upravit PSČ</Trans>
              </button>
            </div>
            <Spacer className="h-6" />
            {cropPrices.status === "pending" ? (
              <div className="flex basis-full items-center justify-center">
                <Spinner />
              </div>
            ) : null}
            {cropPrices.status === "error" ? (
              <Failed error={cropPrices.error} />
            ) : null}
            {cropPrices.status === "success" ? (
              <div className="flex flex-col gap-y-8">
                {[
                  {
                    items: favoriteCropPrices,
                    icon: starFilledSrc,
                    iconTitle: t`Odebrat z oblíbených`,
                    title: (
                      <span
                        className="flex items-center gap-2"
                        title="Zaškrtnutím hvězdičky přidáte komoditu do oblíbených"
                      >
                        <img src={starOutlineSrc} />{" "}
                        <Trans>Moje oblíbené komodity</Trans>
                      </span>
                    ),
                    titleTooltip: t`Zaškrtnutím hvězdičky u jednotlivých komodit je přesunete v seznamu výše`,
                  },
                  {
                    items: otherCropPrices,
                    icon: starSrc,
                    iconTitle: t`Přidat do oblíbených`,
                    title: t`Ostatní komodity`,
                  },
                ].map((section) => (
                  <section
                    key={section.iconTitle}
                    className="flex flex-col gap-4"
                  >
                    <h2
                      className="flex items-center gap-2 font-bold text-can-forest-teal"
                      title={section.titleTooltip}
                    >
                      {section.title}
                    </h2>

                    <div
                      className={`grid w-full gap-6 ${isRightPaneOpen ? "grid-cols-1 2xl:grid-cols-2" : "grid-cols-1 xl:grid-cols-3"}`}
                    >
                      {section.items.map((item) => (
                        <section
                          className="flex flex-col gap-y-2 rounded-2xl p-4 shadow-can-light-box"
                          key={item.crop.name}
                        >
                          <h2 className="flex items-center gap-x-2 font-bold text-can-midnight-steel">
                            <CropIcon cropName={item.crop.name} />{" "}
                            {item.crop.name}
                            <button
                              title={section.iconTitle}
                              onClick={() => toggleFavorite(item.crop.name)}
                              className="group -mr-2 ml-auto flex content-center rounded-full p-2 hover:bg-can-silver-gray"
                            >
                              <img
                                src={section.icon}
                                className="group-hover:hidden"
                              />
                              <img
                                src={starOutlineSrc}
                                className="hidden group-hover:inline"
                              />
                            </button>
                          </h2>
                          {getPricesView(item.prices).map((prices, i) => (
                            <PriceList
                              renderPrice={(price) =>
                                price ? (
                                  <WrapMeWith
                                    when={
                                      (featureFlag.get("trading") &&
                                        featureFlag.get("premium")) ??
                                      false
                                    }
                                    wrapper={(children) => (
                                      <SearchParamsLink
                                        className="flex flex-col gap-y-2 text-can-slate-blue-gray"
                                        to={price.id}
                                      >
                                        {children}
                                      </SearchParamsLink>
                                    )}
                                  >
                                    <PriceItem price={price} />
                                  </WrapMeWith>
                                ) : null
                              }
                              className={
                                i === 0 && prices.length === 1
                                  ? "[&>li]:col-start-4 [&>li]:border-l [&>li]:border-r-0"
                                  : ""
                              }
                              key={i}
                              prices={prices.map((p) =>
                                p?.type === "data"
                                  ? {
                                      id: p.id,
                                      quarter: p.quarter.name,
                                      value: p.price,
                                      currency,
                                    }
                                  : null,
                              )}
                            />
                          ))}
                        </section>
                      ))}
                    </div>
                  </section>
                ))}
              </div>
            ) : null}
            <Spacer />
            {params?.enforceDialog ? <PostalCodeDialog /> : null}
            <Spacer />
            {cropPrices.dataUpdatedAt > 0 ? (
              <p className="mb-8 mt-auto text-center text-sm">
                <Trans>Aktualizováno</Trans>{" "}
                {DateTime.fromMillis(cropPrices.dataUpdatedAt).toLocaleString(
                  DateTime.DATETIME_FULL,
                )}
              </p>
            ) : null}
          </div>
        ),
      }}
    ></TwoColumnLayout>
  );
};

type QuarterPrice = {
  id: string;
  price: number;
  quarter: {
    name: string;
    id: string;
  };
};

type QuarterPricePlaceholder = Pick<QuarterPrice, "quarter"> & {
  type: "placeholder";
};

type QuarterPriceOutput = QuarterPrice & { type: "data" };

const getPricesView = (
  quarters: readonly QuarterPrice[],
): Array<Array<QuarterPriceOutput | null | QuarterPricePlaceholder>> => {
  const result: Array<QuarterPriceOutput | null | QuarterPricePlaceholder> = [];
  if (quarters.length === 0) {
    return [result];
  }

  const first = quarters.at(0)!;

  if (first.quarter.name.startsWith("Q1")) {
    result.push(...[null, null]);
  } else if (first.quarter.name.startsWith("Q2")) {
    result.push(...[null, null, null]);
  } else if (first.quarter.name.startsWith("Q3")) {
    // we don't need no placeholders for Q3
  } else {
    result.push(null);
  }

  result.push({ ...first, type: "data" });
  const withoutFirst = quarters.slice(1);

  // fill the potential gaps
  while (withoutFirst.length) {
    const current = () => result.at(-1);
    const next = withoutFirst.shift();

    if (!(current() && next)) {
      break;
    }

    while (!isNextQuarter(current()!.quarter.name, next.quarter.name)) {
      const generatedQuarterName = getNextQuarter(current()!.quarter.name);
      result.push({
        type: "placeholder",
        quarter: {
          name: generatedQuarterName,
          id: generatedQuarterName,
        },
      });
    }
    result.push({ ...next, type: "data" });
  }

  return chunk(result, 4);
};

const initialParams = createSearchParams({
  enforceDialog: "true",
  currency: "CZK",
});

const usePurchasePricesParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const parsedParams = purchasePricesSchema.safeParse(
    Object.fromEntries(searchParams),
  );

  const enforceDialog = () => {
    setSearchParams((cur) => {
      cur.set("enforceDialog", "true");
      return cur;
    });
  };

  const closeDialog = () => {
    setSearchParams(() => {
      // https://github.com/remix-run/react-router/issues/9757 what a nasty buggy behavior this is
      const params = createSearchParams(window.location.search);
      if (params.get("postalCode")) {
        params.delete("enforceDialog");
      }

      return params;
    });
  };

  const setPostalCode = (postalCode: string) => {
    if (postalCode) {
      setSearchParams((cur) => {
        cur.set("postalCode", postalCode);
        cur.delete("enforceDialog");
        return cur;
      });
    }
  };

  return {
    params: parsedParams.success ? parsedParams.data : undefined,
    enforceDialog,
    closeDialog,
    setPostalCode,
  };
};

const PurchasePricesParams = ({
  children,
  params,
}: React.PropsWithChildren<{ params: string }>) => {
  const searchParams = usePurchasePricesParams();
  const { pathname } = useLocation();

  if (!searchParams.params) {
    return <Navigate to={`${pathname}?${params}`} />;
  }

  return children;
};

const purchasePricesSchema = z
  .object({
    postalCode: z.string().refine((value) => !isNaN(parseInt(value, 10))),
    enforceDialog: z
      .string()
      .refine((value) => value === "true" || value === "false")
      .transform((value) => (value === "true" ? true : false))
      .or(z.undefined()),
  })
  .merge(currencySchema)
  .or(
    z
      .object({
        postalCode: z
          .string()
          .refine((value) => !isNaN(parseInt(value, 10)))
          .or(z.undefined()),
        enforceDialog: z
          .string()
          .refine((value) => value === "true" || value === "false")
          .transform((value) => (value === "true" ? true : false)),
      })
      .merge(currencySchema),
  );

const Restoration = ({
  children,
}: {
  children: (params: string) => React.ReactNode;
}) => {
  const postalCodes = useQuery(postalCodesQuery());
  const { params } = usePurchasePricesParams();
  const [savedParams, setSavedParams] = useLocalStorage<string>(path);
  const savedURLParams = createSearchParams(savedParams);
  const postalCode = savedURLParams.get("postalCode");
  const isValidPostalCode =
    postalCodes.data?.find((code) => code.code === postalCode) !== undefined;

  React.useEffect(() => {
    if (!params) {
      return;
    }
    setSavedParams(
      Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&"),
    );
  }, [params, setSavedParams]);

  if (postalCodes.status !== "success") {
    return null;
  }

  return (
    <>{children(isValidPostalCode ? savedParams : initialParams.toString())}</>
  );
};

export {
  getPricesView,
  path,
  PurchasePrices,
  PurchasePricesParams,
  Restoration,
  usePurchasePricesParams,
};

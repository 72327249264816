import Cross from "@icons/cross.svg?react";
import { Suspense } from "react";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet, useLocation } from "react-router-dom";

import { Failed } from "@/common/components/info/info";
import { PendingUserBanner } from "@/common/components/pending-user-banner/pending-user-banner";
import { SideBar } from "@/common/components/side-bar/side-bar";
import { Spinner } from "@/common/components/spinner/spinner";
import { TopBar } from "@/common/components/top-bar/top-bar";
import { UserProfile } from "@/common/components/user-profile/user-profile";
import { useScrollRestoration } from "@/common/hooks/use-scroll-restoration";
import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";
import { SidebarScreen } from "@/common/providers/sidebar-provider/sidebar-screen";

import { FullscreenModalLayout } from "./layout.fullscreen";

const Layout = () => {
  const location = useLocation();
  const Jsx = (
    <>
      <TopBar />
      <UserProfile />
      <SideBar key={`sidebar-${location.pathname}`} />
    </>
  );

  return (
    <>
      {Jsx}
      <PendingUserBanner />
      <div className="can-sm-layout ml-6 flex h-full pt-8 has-[>_:nth-child(1):last-child]:mr-6 sm:ml-[calc(theme(spacing.20)+theme(spacing.6))] sm:pt-14">
        <Outlet />
      </div>
    </>
  );
};

const TwoColumnLayout = ({
  left,
  right,
}: {
  left: {
    header: React.ReactNode;
    content: React.ReactNode;
  };
  right: boolean;
}) => {
  const { state: sidebar, actions } = useSidebar();
  const isSidebarOpen = !!sidebar.screen;
  const hasRightSide = right || isSidebarOpen;
  const { handleLinkClick, focusClickedElement, handleScroll } =
    useScrollRestoration(hasRightSide);

  focusClickedElement();

  return (
    <>
      <div
        onClick={handleLinkClick}
        className="mt-14 flex w-full flex-grow flex-col"
      >
        <div className={`${hasRightSide ? "mr-6" : ""} flex justify-between`}>
          {left.header}
        </div>
        <div
          onScroll={handleScroll}
          className={` flex w-full basis-full flex-col gap-y-6  pt-4 ${
            hasRightSide ? "overflow-y-auto pr-6" : ""
          }
`}
        >
          {left.content}
        </div>
      </div>
      {hasRightSide ? (
        <aside className="border-can-can-silver-cloud grid max-w-[500px] overflow-y-auto sm:mt-[18px] sm:border-l lg:min-w-[600px] lg:max-w-[750px]">
          <Suspense
            fallback={
              <FullscreenModalLayout headerButton={null}>
                <Spinner />
              </FullscreenModalLayout>
            }
          >
            <Outlet />
          </Suspense>
          {isSidebarOpen ? (
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <FullscreenModalLayout
                  headerButton={
                    <button onClick={actions.toggle}>
                      <Cross />
                    </button>
                  }
                >
                  <Failed error={error} />
                </FullscreenModalLayout>
              )}
            >
              <Suspense
                fallback={
                  <FullscreenModalLayout headerButton={null}>
                    <Spinner />
                  </FullscreenModalLayout>
                }
              >
                <SidebarScreen />
              </Suspense>
            </ErrorBoundary>
          ) : null}
        </aside>
      ) : null}
    </>
  );
};

export { Layout, TwoColumnLayout };

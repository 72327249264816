import { useAuth0 } from "@auth0/auth0-react";
import CircleUserSvg from "@icons/circle-user.svg?react";
import { Trans } from "@lingui/react/macro";

import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";

import { NotificationBell } from "../push-notifications/push-notifications";
import { UserMenu } from "../user/user-menu/user-menu";

const UserProfile = () => {
  const { user, isLoading } = useAuth0();
  const { actions } = useSidebar();

  if (isLoading) {
    return <Trans>Načítá se</Trans>;
  }

  if (user) {
    return (
      <div className="sm-can-top-bar fixed ml-[72px] hidden w-[calc(100%-theme(spacing.20))] justify-end gap-6 bg-white pr-6 pt-6 sm:z-[1] sm:flex">
        <NotificationBell />
        <div data-testid="user-profile" className="group relative flex gap-2">
          <CircleUserSvg className="h-6 w-6" />
          <b className="font-can-inter font-bold text-can-forest-teal">
            {user.name}
          </b>
          <div className="invisible absolute right-0 w-[250px] group-hover:visible">
            <UserMenu.Desktop>
              <UserMenu
                renderItem={{
                  profile: (item) => (
                    <button
                      className="flex w-full gap-x-4 p-6 first:rounded-t-2xl hover:bg-can-silver-gray"
                      onClick={() => actions.openScreen("user-profile")}
                    >
                      {item.icon}
                      {item.label}
                    </button>
                  ),
                  subscription: (item) => (
                    <button
                      className="flex w-full gap-x-4 p-6 hover:bg-can-silver-gray"
                      onClick={() => actions.openScreen("subscriptions")}
                    >
                      {item.icon}
                      {item.label}
                    </button>
                  ),
                }}
              />
            </UserMenu.Desktop>
          </div>
        </div>
      </div>
    );
  }
};

export { UserProfile };

import { ErrorMessage } from "@hookform/error-message";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useSuspenseQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import React from "react";
import { useForm } from "react-hook-form";
import invariant from "tiny-invariant";

import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";
import { currencyFormatter, getPrice } from "@/common/services/formatter";
import { getLocalizedPeriods } from "@/common/utils/period";
import { splitCallingCodeAndCountry } from "@/common/utils/phone";
import { PlanPeriodToType } from "@/generated/api/users";
import { FullscreenModalLayout } from "@/routes/layout/layout.fullscreen";

import { Button } from "../button/button";
import { CompanyForm, getInitialValues } from "../company-form/company-form";
import { PropertiesList } from "../properties-list/properties-list";
import { Spacer } from "../spacer/spacer";
import { companyQuery } from "../user-settings/components/company-form/company-form.api";
import {
  paymentMethodsQuery,
  useCreateBankTransferSubscriptionMutation,
  useCreateCardSubscriptionMutation,
} from "./subscription.api";

type Fields = {
  termsOfService: boolean;
};

const SubscriptionOverview = () => {
  const sidebar = useSidebar();
  invariant(sidebar.state.screen === "subscriptions-overview");
  const { plan, price, period, periodId } = sidebar.state;
  const {
    data: { company, disabledFields },
  } = useSuspenseQuery(companyQuery());
  const paymentMethods = useSuspenseQuery(
    paymentMethodsQuery({ countryId: company.country }),
  );
  const form = useForm<Fields>();
  const termsOfService = form.watch("termsOfService");
  const cardRef = React.useRef<HTMLButtonElement>(null);
  const bankTransferRef = React.useRef<HTMLButtonElement>(null);
  const createCardSubscription = useCreateCardSubscriptionMutation();
  const createBankTransferSubscription =
    useCreateBankTransferSubscriptionMutation();
  const isPending =
    createCardSubscription.status === "pending" ||
    createBankTransferSubscription.status === "pending";

  const handleSubmit: React.ComponentProps<
    typeof CompanyForm
  >["onSubmit"] = async (data, e) => {
    const isCardPayment =
      cardRef.current === (e?.nativeEvent as SubmitEvent).submitter;
    const isBankTransferPayment =
      bankTransferRef.current === (e?.nativeEvent as SubmitEvent).submitter;
    await form.trigger("termsOfService");
    const termsOfService = form.formState.errors.termsOfService;

    if (termsOfService) {
      termsOfService.ref?.scrollIntoView?.();
      return;
    }

    if (isCardPayment) {
      createCardSubscription.mutate(
        {
          periodId,
          billing: {
            vat: data.vat,
            email: data.contact.email,
            address: data.address,
            phone: {
              phoneNumber: data.contact.phone.phoneNumber,
              countryCode: splitCallingCodeAndCountry(
                data.contact.phone.countryCode,
              )[0],
            },
          },
        },
        {
          onError: (error) => {
            sidebar.actions.openPaymentCard({
              error,
            });
          },
          onSuccess: (data) => {
            invariant(data.details?.stripe);
            const priceWithVAT = currencyFormatter(data.currency.type).format(
              getPrice(data.amountTotal, data.currency.precision),
            );
            sidebar.actions.openPaymentCard({
              price,
              priceWithVAT,
              clientSecret: data.details.stripe.clientSecret,
              publicKey: data.details.stripe.publicKey,
            });
          },
        },
      );
    }
    if (isBankTransferPayment) {
      createBankTransferSubscription.mutate(
        {
          periodId,
          billing: {
            vat: data.vat,
            email: data.contact.email,
            address: data.address,
            phone: {
              phoneNumber: data.contact.phone.phoneNumber,
              countryCode: splitCallingCodeAndCountry(
                data.contact.phone.countryCode,
              )[0],
            },
          },
        },
        {
          onError: (error) => {
            sidebar.actions.openPaymentBankTransfer({ error });
          },
          onSuccess: (data) => {
            invariant(data.details?.bank);
            const invoice = data.invoice.at(0);
            invariant(invoice);
            const priceWithVAT = currencyFormatter(data.currency.type).format(
              getPrice(data.amountTotal, data.currency.precision),
            );
            sidebar.actions.openPaymentBankTransfer({
              price,
              priceWithVAT,
              plan,
              variableSymbol: data.details.bank.variableSymbol,
              gatewayId: data.gatewayId!,
              swift: data.details.bank.swift,
              iban: data.details.bank.iban,
              accountNumber: data.details.bank.account,
              email: data.billing.email,
              invoiceId: invoice.id,
            });
          },
        },
      );
    }
  };

  React.useEffect(() => {
    if (!form.formState.dirtyFields.termsOfService) {
      return;
    }
    form.trigger("termsOfService").catch(() => {});
  }, [form, termsOfService]);

  return (
    <>
      <FullscreenModalLayout.Title>
        <Trans>Přehled a volba platební metody</Trans>
      </FullscreenModalLayout.Title>
      <h2 className="text-md font-bold text-can-forest-teal">
        <Trans>Shrnutí</Trans>
      </h2>
      <Spacer className="h-2" />
      <PropertiesList
        properties={[
          {
            label: t`Předplatné`,
            value: plan,
          },
          {
            label: t`Cena (bez DPH)`,
            value: price,
          },
          {
            label: t`Délka předplatného`,
            value: getLocalizedPeriods()[period],
          },
          {
            label: t`Období`,
            value: getSubscriptionRange(period),
          },
          {
            label: t`Další platba`,
            value: getNextPaymentDate(period),
          },
        ]}
      />
      <Spacer className="h-2" />
      <h2 className="text-md font-bold text-can-forest-teal">
        <Trans>Společnost</Trans>
      </h2>
      <Spacer className="h-2" />
      <CompanyForm
        onSubmit={handleSubmit}
        disabledFields={disabledFields}
        omitFields={["contact.role", "contact.name", "legalForm"]}
        initialValues={getInitialValues(company)}
      />
      <Spacer className="h-6" />
      <label
        className="relative flex h-6 flex-wrap justify-between text-sm"
        htmlFor="termsOfService"
      >
        <span>
          <Trans>
            Souhlasím s obchodními podmínkami{" "}
            <a
              href="https://www.cleveranalytics.ag/vseobecne-obchodni-podminky"
              target="_blank"
              rel="noreferrer"
            >
              služby
            </a>
          </Trans>
        </span>
        <input
          {...form.register("termsOfService", {
            required: t`Bez souhlasu s obchodními podmínkami nelze pokračovat`,
          })}
          type="checkbox"
          role="switch"
        />
      </label>
      <Spacer className="h-1" />
      <ErrorMessage
        errors={form.formState.errors}
        name="termsOfService"
        render={({ message }) => (
          <p
            role="alert"
            className="basis-full text-left text-xs text-can-russet"
          >
            {message}
          </p>
        )}
      />
      <Spacer className="h-6" />
      <Button.Container>
        {paymentMethods.data.includes("CARD") ? (
          <Button
            disabled={isPending}
            ref={cardRef}
            form="company-form"
            variant="primary"
          >
            <Trans>Zaplatit kartou</Trans>
          </Button>
        ) : null}
        {paymentMethods.data.includes("BANK_TRANSFER") ? (
          <Button
            ref={bankTransferRef}
            disabled={isPending}
            form="company-form"
            variant="secondary"
          >
            <Trans>Zaplatit převodem</Trans>
          </Button>
        ) : null}
      </Button.Container>
    </>
  );
};

const getNextPaymentDate = (period: PlanPeriodToType) => {
  switch (period) {
    case "DAILY":
      return DateTime.now().plus({ day: 1 }).toLocaleString();
    case "YEARLY":
      return DateTime.now().plus({ year: 1 }).toLocaleString();
    case "MONTHLY":
      return DateTime.now().plus({ month: 1 }).toLocaleString();
    case "QUARTERLY":
      return DateTime.now().plus({ quarter: 1 }).toLocaleString();
    default: {
      const exhaustiveCheck: never = period;
      // eslint-disable-next-line lingui/no-unlocalized-strings
      throw new Error(`Unhandled status case: ${exhaustiveCheck}`);
    }
  }
};

const getSubscriptionRange = (period: PlanPeriodToType) => {
  const interval = [DateTime.now()];
  switch (period) {
    case "DAILY":
      interval.push(interval[0].plus({ day: 1 }));
      break;
    case "YEARLY":
      interval.push(interval[0].plus({ year: 1 }));
      break;
    case "MONTHLY":
      interval.push(interval[0].plus({ month: 1 }));
      break;
    case "QUARTERLY":
      interval.push(interval[0].plus({ quarter: 1 }));
      break;
    default: {
      const exhaustiveCheck: never = period;
      // eslint-disable-next-line lingui/no-unlocalized-strings
      throw new Error(`Unhandled status case: ${exhaustiveCheck}`);
    }
  }

  return (
    interval[0].toLocaleString(DateTime.DATE_SHORT) +
    " - " +
    interval[1].toLocaleString(DateTime.DATE_SHORT)
  );
};

export { SubscriptionOverview };

import Cross from "@icons/cross.svg?react";
import Search from "@icons/search.svg?url";
import { t } from "@lingui/core/macro";
import { useSearchParams } from "react-router-dom";

const useSearchFieldParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get("search") ?? "";
  const setValue = (search: string) => {
    setSearchParams((c) => {
      if (search.length > 0) {
        c.set("search", search);
      } else {
        c.delete("search");
      }
      return c;
    });
  };
  const clearValue = () => {
    setSearchParams((c) => {
      c.delete("search");
      return c;
    });
  };

  return [value, setValue, clearValue] as const;
};

const SearchField = () => {
  const [value, setValue, clearValue] = useSearchFieldParams();
  return (
    <div className="relative">
      <input
        placeholder={t`Hledat sklad nebo komoditu`}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        type="text"
        className={`mt-2 block w-full rounded-lg border p-4 font-bold group-has-[[role=alert]]:border-can-russet ${value.length > 0 ? "!bg-none" : ""}`}
        style={{
          background: `url(${Search}) right 16px center no-repeat`,
        }}
      />
      {value.length > 0 ? (
        <button onClick={clearValue} className="absolute right-4 top-[25px]">
          <Cross />
        </button>
      ) : null}
    </div>
  );
};

export { SearchField, useSearchFieldParams };

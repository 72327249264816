import { Trans } from "@lingui/react/macro";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { resetForm } from "@/api/form-reset";
import { Spinner } from "@/common/components/spinner/spinner";
import { cancelPayment, confirmPayment } from "@/generated/api/users";

type Fields = {
  status: "CANCEL" | "CONFIRM" | "";
};

const Action = ({ paymentId }: { paymentId: number }) => {
  const confirmPayment = useConfirmPaymentMutation();
  const cancelPayment = useCancelPaymentMutation();
  const queryClient = useQueryClient();
  const form = useForm<Fields>();
  const isPending =
    confirmPayment.status === "pending" || cancelPayment.status === "pending";

  const handleSubmit = (data: Fields) => {
    if (data.status === "") {
      return;
    }

    const mutation = data.status === "CANCEL" ? cancelPayment : confirmPayment;
    mutation.mutate(
      { paymentId },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries();
        },
        onError: resetForm(form),
      },
    );
  };

  return (
    <form>
      <select
        {...form.register("status", {
          onChange: async () => {
            await form.handleSubmit(handleSubmit)();
          },
        })}
      >
        <option value="">
          <Trans>Akce</Trans>
        </option>
        <option value="CONFIRM">
          <Trans>Přijmout platbu</Trans>
        </option>
        <option value="CANCEL">
          <Trans>Zrušit platbu</Trans>
        </option>
      </select>
      {isPending ? <Spinner className="!h-4 !w-4" withHint={false} /> : null}
    </form>
  );
};

const useConfirmPaymentMutation = () =>
  useMutation({
    mutationFn: ({ paymentId }: { paymentId: number }) =>
      confirmPayment(paymentId),
  });

const useCancelPaymentMutation = () =>
  useMutation({
    mutationFn: ({ paymentId }: { paymentId: number }) =>
      cancelPayment(paymentId),
  });

export { Action };

import React from "react";
import { useNavigate } from "react-router-dom";

import { useToggle } from "@/common/hooks/use-toggle";
import { useTokenUpdate } from "@/common/providers/token-provider";

const useGoBackToApp = () => {
  const navigate = useNavigate();
  const [isUpdatingToken, updateToken] = useTokenUpdate();
  const readyToNavigate = useToggle();

  const handleRedirectClick = async () => {
    await updateToken();
    readyToNavigate.setOn();
  };

  React.useEffect(() => {
    if (isUpdatingToken || !readyToNavigate.on) {
      return;
    }
    navigate("/");
  }, [navigate, isUpdatingToken, readyToNavigate.on]);

  return { isUpdatingToken, handleRedirectClick };
};

export { useGoBackToApp };

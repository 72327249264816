import rehypeDomStringify from "rehype-dom-stringify";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import { unified } from "unified";

const getHTML = async (markdown: string): Promise<Document> => {
  const html = await unified()
    .use(remarkParse)
    .use(remarkRehype)
    .use(rehypeDomStringify)
    .process(markdown);
  const doc = document.implementation.createHTMLDocument();
  doc.body.innerHTML = html.toString();

  return doc;
};

const nullIf = <T>(input: T, nullLikeValue: T) => {
  if (input === nullLikeValue) {
    return null;
  }

  return input;
};

export { getHTML, nullIf };

import { useAuth0 } from "@auth0/auth0-react";
import Cross from "@icons/cross.svg?react";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe, StripeElementLocale } from "@stripe/stripe-js";
import {
  queryOptions,
  useMutation,
  useSuspenseQuery,
} from "@tanstack/react-query";
import invariant from "tiny-invariant";

import { toaster } from "@/common/components/toast/toast";
import { useToggle } from "@/common/hooks/use-toggle";
import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";
import { getUser } from "@/common/services/user";
import { FullscreenModalLayout } from "@/routes/layout/layout.fullscreen";

import { Button } from "../button/button";
import { Spacer } from "../spacer/spacer";

const PaymentCard = ({
  children,
  clientSecret,
}: React.PropsWithChildren<{
  clientSecret: string;
}>) => {
  const stripeReady = useToggle();
  const locale = useLocale();
  const sidebar = useSidebar();
  invariant(sidebar.state.screen === "payment-card");
  const stripeConfig = useSuspenseQuery(
    loadStripeQuery({ key: sidebar.state.publicKey! }),
  );

  return (
    <FullscreenModalLayout
      headerButton={
        <button onClick={sidebar.actions.toggle}>
          <Cross />
        </button>
      }
    >
      <FullscreenModalLayout.Title>
        <Trans>Zaplatit předplatné</Trans>
      </FullscreenModalLayout.Title>
      <Elements
        stripe={stripeConfig.data}
        options={{
          loader: "always",
          fonts: [
            {
              cssSrc:
                "https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Roboto:wght@400;700;900&display=swap",
            },
          ],
          appearance: {
            theme: "stripe",
            variables: {
              // eslint-disable-next-line lingui/no-unlocalized-strings
              fontFamily: "Roboto",
              borderRadius: "8px",
              colorText: "#616161",
              colorDanger: "#E55743",
              colorDangerText: "#E55743",
            },
            rules: {
              ".Input": {
                padding: "16px",
              },
              ".Label": {
                fontSize: "16px",
                marginBottom: "8px",
                marginTop: "16px",
              },
            },
          },
          locale,
          clientSecret,
        }}
      >
        <PaymentElement onReady={stripeReady.setOn} />
        {stripeReady.on ? (
          <>
            <p className="text-xs text-[rgb(113,113,113)]">
              <Trans>
                Ukončení předplatného můžete spravovat v sekci Předplatné,
                kterou najdete ve vašem profilu. Cleverfarm využívá pro platby
                kartou technologii Stripe, která splňuje nejpřísnější
                bezpečností požadavky pro platební brány a platby kartou v EU.
              </Trans>
            </p>
            {children}
          </>
        ) : null}
      </Elements>
    </FullscreenModalLayout>
  );
};

const SubmitButton = ({ price }: { price: string }) => {
  const confirmPayment = useConfirmPaymentMutation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    confirmPayment.mutate(undefined, {
      onError: (error) => {
        toaster.error(
          {
            title: t`Platba selhala`,
            text: error.message,
          },
          { autoClose: 5000 },
        );
      },
    });
  };

  return (
    <>
      <Spacer className="h-6" />
      <Button
        disabled={confirmPayment.status === "pending"}
        onClick={handleSubmit}
        variant="primary"
      >
        <Trans>Zaplatit</Trans> {price}
      </Button>
    </>
  );
};

const loadStripeQuery = ({ key }: { key: string }) =>
  queryOptions({
    queryKey: ["payment", "stripe", key],
    queryFn: () => loadStripe(key),
    staleTime: Infinity,
  });

const useLocale = () => {
  const { user: _user } = useAuth0();
  invariant(_user);
  const user = getUser(_user);

  return user.user_metadata.can.settings?.language
    .split("-")
    .at(0) as StripeElementLocale;
};

const useConfirmPaymentMutation = () => {
  const stripe = useStripe();
  const elements = useElements();
  invariant(stripe);
  invariant(elements);

  return useMutation({
    mutationFn: async () => {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin + "/subscribed",
        },
      });

      return result;
    },
  });
};

export { PaymentCard, SubmitButton };

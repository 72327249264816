import TimesHexagonSvg from "@icons/times-hexagon.svg?react";
import TrashGradientSvg from "@icons/trash-gradient.svg?react";
import { Trans } from "@lingui/react/macro";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";

import { Button } from "../button/button";
import { Spacer } from "../spacer/spacer";
import {
  subscriptionQuery,
  useCancelSubscriptionMutation,
} from "./subscription.api";
import { useCancelSubscriptionScreen } from "./use-subscription-screen";

const SubscriptionCancel = () => {
  const sidebar = useSidebar();
  const { subscriptionId } = useCancelSubscriptionScreen();
  const subscription = useSuspenseQuery(
    subscriptionQuery({ id: subscriptionId }),
  );
  const cancelSubscription = useCancelSubscriptionMutation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleCancelSubscription = () => {
    cancelSubscription.mutate(
      { id: subscriptionId },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries();
          navigate("/unsubscribed");
        },
        onError: () => {
          sidebar.actions.openScreen("subscriptions-cancel-failure");
        },
      },
    );
  };

  return (
    <>
      <Spacer className="h-6" />
      <h1 className="text-2xl font-black text-can-forest-teal">
        <Trans>Zrušit předplatné</Trans>
      </h1>
      <Spacer className="h-6" />
      <div className="flex h-[160px] w-[160px] content-center self-center rounded-full bg-can-silver-gray p-11">
        <TrashGradientSvg className="h-[72px] w-[72px] self-center" />
      </div>
      <p className="mt-6 text-center">
        <Trans>Opravdu si přejete zrušit vaše předplatné?</Trans>
      </p>
      <p className="text-center">
        <Trans>
          Přijdete o přístup do aplikace, jakmile vám skončí aktuální
          předplatné.
        </Trans>
      </p>
      <p className="mt-4 text-center">
        <strong>
          <Trans>Vaše předplatné vyprší {subscription.data.expiresAt}.</Trans>
        </strong>
      </p>
      <Button.Container>
        <Button
          onClick={handleCancelSubscription}
          disabled={cancelSubscription.status === "pending"}
          variant="primary"
        >
          <Trans>Zrušit předplatné</Trans>
        </Button>
      </Button.Container>
    </>
  );
};

const Failure = () => {
  const sidebar = useSidebar();
  return (
    <>
      <Spacer className="h-6" />
      <h1 className="text-2xl font-black text-can-forest-teal">
        <Trans>Zrušit předplatné se nepodařilo</Trans>
      </h1>
      <Spacer className="h-6" />
      <div className="flex h-[160px] w-[160px] content-center self-center rounded-full bg-can-silver-gray p-11">
        <TimesHexagonSvg className="h-[72px] w-[72px] self-center" />
      </div>
      <p className="mt-6 text-center">
        <Trans>
          Bohužel se vaše předplatné nepodařilo zrušit, jelikož někde nastala
          chyba. Zkuste to prosím znovu později.
        </Trans>
      </p>
      <Button.Container>
        <Button
          onClick={() => sidebar.actions.openScreen("subscriptions")}
          variant="primary"
        >
          <Trans>Přejít na předplatné</Trans>
        </Button>
      </Button.Container>
    </>
  );
};

export { Failure, SubscriptionCancel };

import { Plural, Trans } from "@lingui/react/macro";
import { DateTime } from "luxon";

import { currencyFormatter } from "@/common/services/formatter";
import { ContractStatus } from "@/generated/digitalnisklady.cz/graphql";

type Props = {
  id: string;
  amount: number;
  currency: "EUR" | "CZK";
  storage: {
    name: string;
  };
  price: number;
  timeCreated: DateTime;
  className?: string;
  status?: React.ReactNode;
};

/**
 * This one is tricky, but an `<a />` element is expected as a child. The component itself can't be wrapped in such element,
 * because of the trash bin button we have here.
 */
const ContractItem = (props: React.PropsWithChildren<Props>) => {
  return (
    <li
      className={`relative flex min-h-[100px] cursor-pointer rounded-2xl px-4 py-3 shadow-can-light-box [&_a]:absolute [&_a]:inset-0 ${props.className ?? ""}`}
      key={props.id}
    >
      <div className="flex w-full flex-col gap-y-2">
        <div className="flex flex-nowrap justify-between gap-2">
          <h3 className="font-bold text-can-midnight-steel">
            {currencyFormatter(props.currency).format(props.price)}
            <Trans context="unit of weight">/t</Trans>
          </h3>
          {props.status}
        </div>
        {props.children}
        <p className="flex gap-1 text-xs">
          <span>
            {props.timeCreated.toLocaleString(DateTime.DATETIME_SHORT)}
          </span>
          <span aria-hidden>|</span>
          <span>
            <Plural
              _0="-"
              one="# tuna"
              two="# tuny"
              few="# tuny"
              other="# tun"
              value={props.amount}
            />
          </span>
        </p>
      </div>
    </li>
  );
};

const Status = ({ status }: { status: ContractStatus }) => {
  let item: { gradient: string; text: React.ReactNode } = {} as typeof item;

  switch (status) {
    case ContractStatus.Failed: {
      item = {
        text: <Trans context="contract status">Odmítnuto</Trans>,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        gradient: "from-[#F99B87] to-[#E55743]",
      };
      break;
    }
    case ContractStatus.Pending: {
      item = {
        text: <Trans context="contract status">Čeká na vyřízení</Trans>,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        gradient: "from-can-tranquil-azure to-can-mystic-aqua",
      };
      break;
    }
    case ContractStatus.Processing: {
      item = {
        text: <Trans context="contract status">Zpracovává se</Trans>,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        gradient: "from-can-tranquil-azure to-can-mystic-aqua",
      };
      break;
    }
    case ContractStatus.Success: {
      item = {
        text: <Trans context="contract status">Vyřízeno</Trans>,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        gradient: "from-[#84D4A3] to-[#51B678]",
      };
      break;
    }
    case ContractStatus.Confirmed: {
      item = {
        text: <Trans context="contract status">Připraveno k podpisu</Trans>,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        gradient: "from-[#FFC684] to-[#FF914D]",
      };
      break;
    }
    case ContractStatus.Signed: {
      item = {
        text: <Trans context="contract status">Podepsáno</Trans>,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        gradient: "from-[#84D4A3] to-[#51B678]",
      };
      break;
    }
    default: {
      const exhaustiveCheck: never = status;
      // eslint-disable-next-line lingui/no-unlocalized-strings
      throw new Error(`Unhandled status case: ${exhaustiveCheck}`);
    }
  }

  return (
    <small
      className={`rounded bg-can-gradient-to-r-80 ${item.gradient} p-1.5 text-right text-xs font-normal text-white`}
    >
      {item.text}
    </small>
  );
};

ContractItem.Status = Status;

export { ContractItem };

import { useAuth0 } from "@auth0/auth0-react";
import { ErrorMessage } from "@hookform/error-message";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import invariant from "tiny-invariant";
import { z } from "zod";

import { BackgroundContainer } from "@/common/components/background-container/background-container";
import { Button } from "@/common/components/button/button";
import { PhoneNumber } from "@/common/components/form/phone-number/phone-number";
import { Failed } from "@/common/components/info/info";
import { Metadata } from "@/common/components/metadata/metadata";
import { Spinner } from "@/common/components/spinner/spinner";
import { useTokenUpdate } from "@/common/providers/token-provider";
import {
  defaultLanguage,
  fallbackLanguage,
  getLanguage,
} from "@/common/services/i18n";
import { getMaybeUser, IncompleteUserSchema } from "@/common/services/user";
import { getUniqueItems } from "@/common/utils/array";
import {
  combineCallingCodeWithCountry,
  splitCallingCodeAndCountry,
} from "@/common/utils/phone";
import { countryCodeToFlag } from "@/common/utils/unicode";
import { CountryTo } from "@/generated/api/users";

import LogoSvg from "../../assets/logo.svg";
import {
  companyCountriesQuery,
  phoneCountryCodesQuery,
  useCompanyNumberValidationMutation,
  useCompleteUserRegistrationMutation,
} from "./incomplete-user.api";

type Fields = {
  company: {
    number: string;
    countryCode: string;
  };
  name: string;
  phone: {
    countryCode: string;
    phoneNumber: string;
  };
};

const path = "registration/complete";

const IncompleteUser = () => {
  return (
    <BackgroundContainer>
      <BackgroundContainer.Box>
        <img src={LogoSvg} className="h-[72px] w-[72px]" />
        <ErrorBoundary fallbackRender={({ error }) => <Failed error={error} />}>
          <Suspense fallback={<Spinner />}>
            <IncompleteUserForm />
          </Suspense>
        </ErrorBoundary>
      </BackgroundContainer.Box>
    </BackgroundContainer>
  );
};

const getIsCompanyRequired = (
  form: UseFormReturn<Fields>,
  countries: CountryTo[],
) => {
  const [, country] = splitCallingCodeAndCountry(
    form.watch("phone.countryCode"),
  );
  return countries.find(({ id }) => id === country)?.isCompanyRequired ?? true;
};

const IncompleteUserForm = () => {
  const { user: _user } = useAuth0();
  const [isTokenUpdating, updateToken] = useTokenUpdate();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(_user, "User should be defined");
  const user = getMaybeUser(_user);
  const {
    companyCountry,
    currentPhoneCountryCode,
    companyCountries,
    phoneCountryCodes,
  } = useData(user);
  const navigate = useNavigate();
  const form = useForm<Fields>({
    defaultValues: {
      phone: {
        countryCode: currentPhoneCountryCode
          ? combineCallingCodeWithCountry(currentPhoneCountryCode)
          : "",
        phoneNumber: user.user_metadata?.common?.phone?.phoneNumber,
      },
      company: {
        countryCode: companyCountry,
        number: user.user_metadata?.can?.companies?.at(0)?.companyNumber ?? "",
      },
      name: user.name === user.email ? "" : user.name,
    },
  });
  const completeUserRegistration = useCompleteUserRegistrationMutation();
  const validateCompanyNumber = useCompanyNumberValidationMutation();
  const isCompanyRequired = getIsCompanyRequired(form, companyCountries.data);

  const handleCompanyNumberValidation = () => {
    const companyNumber = form.getValues("company.number");
    const countryCode = form.getValues("company.countryCode");

    if (companyNumber === "") {
      form.setError("company.number", {
        message: t`Vyplňte prosím IČO společnosti`,
      });

      return;
    }

    validateCompanyNumber.mutate(
      {
        companyNumber,
        countryCode,
      },
      {
        onError: () => {
          form.setError("company.number", {
            message: t`Společnost nelze identifikovat`,
          });
        },
        onSuccess: () => {
          handleSubmit(form.getValues());
        },
      },
    );
  };

  const handleCountryCompanyChange = () => {
    form.setValue("company.number", "");
    validateCompanyNumber.reset();
  };

  const handleSubmit = (data: Fields) => {
    const payload = {
      name: data.name,
      phone: {
        countryCode: z
          .string()
          .regex(/^\+\d+/)
          .parse(splitCallingCodeAndCountry(data.phone.countryCode).at(0)),
        phoneNumber: data.phone.phoneNumber,
      },
      company: isCompanyRequired
        ? {
            companyNumber: data.company.number.replaceAll(" ", ""),
            countryCode: data.company.countryCode,
          }
        : undefined,
      language: user.user_metadata?.can?.settings?.language ?? defaultLanguage,
    };

    completeUserRegistration.mutate(payload, {
      onSuccess: async () => {
        await updateToken();

        navigate("/");
      },
    });
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={
          isCompanyRequired
            ? form.handleSubmit(handleCompanyNumberValidation)
            : form.handleSubmit(handleSubmit)
        }
        id="register-form"
        className="mt-6 flex h-full flex-col gap-6 bg-white sm:h-auto"
      >
        <h1 className="text-center text-2xl font-bold text-can-forest-teal">
          <Trans>Už jen poslední krok!</Trans>
        </h1>
        <p className="text-center">
          <Trans>
            Pro používání aplikace od vás potřebujeme ještě pár informací, viz
            formulář níže.
          </Trans>
        </p>
        <label className="group" htmlFor="name">
          <Trans>Jméno a příjmení</Trans>
          <input
            {...form.register("name", {
              required: t`Vyplňte prosím jméno a příjmení`,
            })}
            placeholder={t`Vyplňte jméno a příjmení`}
            type="text"
            autoComplete="off"
            className="mt-2 block w-full rounded-lg border p-4 font-bold group-has-[[role=alert]]:border-can-russet"
            id="name"
          />
          <ErrorMessage
            errors={form.formState.errors}
            name="name"
            render={({ message }) => (
              <p
                role="alert"
                className="mt-2 text-left text-xs text-can-russet"
              >
                {message}
              </p>
            )}
          />
        </label>

        <PhoneNumber callingCodes={phoneCountryCodes.data} name="phone" />

        {isCompanyRequired ? (
          <label className="group relative" htmlFor="company.number">
            <Trans>IČO společnosti</Trans>
            <div className="flex gap-2 self-center">
              <select
                {...form.register("company.countryCode", {
                  required: t`Vyplňte prosím zemi registrace`,
                })}
                onChange={handleCountryCompanyChange}
                className="mt-2 h-[calc(theme(spacing.14)+2px)] w-[130px] rounded-lg font-bold group-has-[[role=alert]]:border-can-russet"
              >
                <option disabled value="">
                  <Trans>Země</Trans>
                </option>
                {companyCountries.data.map((country) => (
                  <option value={country.id} key={country.id}>
                    {countryCodeToFlag(country.id)} {country.localName}
                  </option>
                ))}
              </select>
              <div className="flex flex-grow flex-col gap-2">
                <input
                  {...form.register("company.number", {
                    onChange: () => {
                      form.clearErrors("company.number");
                    },
                    required: t`Vyplňte prosím IČO společnosti`,
                  })}
                  placeholder={t`Zadejte IČO společnosti`}
                  type="text"
                  autoComplete="off"
                  className="mt-2 flex w-full rounded-lg border p-4 pr-12 font-bold group-has-[[role=alert]]:border-can-russet"
                  id="company.number"
                />
                <ErrorMessage
                  errors={form.formState.errors}
                  name="company.number"
                  render={({ message }) => (
                    <p
                      role="alert"
                      className="text-left text-xs text-can-russet"
                    >
                      {message}
                    </p>
                  )}
                />
              </div>
            </div>
          </label>
        ) : null}
        <Button.Container>
          <Button
            tabIndex={-1}
            type="button"
            onClick={() => navigate("/logout")}
            variant="secondary"
          >
            <Trans>Odhlásit se</Trans>
          </Button>
          <Button
            disabled={
              completeUserRegistration.status === "pending" ||
              validateCompanyNumber.status === "pending" ||
              isTokenUpdating
            }
            type="submit"
            form="register-form"
            variant="primary"
          >
            <Trans>Uložit a pokračovat do aplikace</Trans>
          </Button>
        </Button.Container>
        <Metadata title={t`Dokončení registrace`} />
      </form>
    </FormProvider>
  );
};

const useData = (user: IncompleteUserSchema) => {
  const language =
    user.user_metadata?.can?.settings?.language ?? fallbackLanguage;
  const companyCountries = useSuspenseQuery(
    companyCountriesQuery({ language }),
  );
  const phoneCountryCodes = useSuspenseQuery(
    phoneCountryCodesQuery({ language }),
  );
  const countriesWithUniqueLanguage = getUniqueItems(
    companyCountries.data,
    (country) => country.language,
  );
  const currentPhoneCountryCode =
    phoneCountryCodes.data.find(
      (country) =>
        country.phoneCountryCode ===
        user.user_metadata?.common?.phone?.countryCode,
    ) ?? getMatchByLanguage(countriesWithUniqueLanguage);
  const companyCountry =
    user.user_metadata?.can?.companies?.at(0)?.countryCode ??
    getMatchByLanguage(countriesWithUniqueLanguage)?.id ??
    "";

  return {
    companyCountry,
    currentPhoneCountryCode,
    companyCountries,
    phoneCountryCodes,
  };
};

const getMatchByLanguage = (countries: CountryTo[]) =>
  countries.find((code) => code.language === getLanguage(navigator.language));

export { IncompleteUser, path };

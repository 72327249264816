import { i18n as defaultI18n } from "@lingui/core";
import { I18nProvider as LinguiI18nProvider } from "@lingui/react";
import React from "react";

import { dynamicActivate, getSupportedLanguage } from "@/common/services/i18n";

const I18nProvider = ({
  i18n = defaultI18n,
  children,
  language,
}: React.PropsWithChildren<{
  language: string;
  i18n?: typeof defaultI18n;
}>) => {
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    setReady(false);
    dynamicActivate(i18n)(getSupportedLanguage(language))
      .then(() => {
        setReady(true);
      })
      .catch(() => {
        setReady(false);
        forceReload();
      });
  }, [language, i18n]);

  return ready ? (
    // @ts-expect-error lingui types are weird here
    <LinguiI18nProvider i18n={i18n}>{children}</LinguiI18nProvider>
  ) : null;
};

const forceReload = () => {
  if (window.history.state.forceReload) {
    return;
  }

  window.history.pushState(
    {
      forceReload: true,
    },
    "",
  );
};

export { I18nProvider };

import { FlagProvider, IConfig } from "@unleash/proxy-client-react";

import { getEnvironmentVariable } from "../services/env";

const config: IConfig = {
  url: getEnvironmentVariable("UNLEASH_PROXY_URL"),
  environment: getEnvironmentVariable("UNLEASH_ENVIRONMENT"),
  clientKey: getEnvironmentVariable("UNLEASH_CLIENT_KEY"),
  refreshInterval: 15,
  appName: getEnvironmentVariable("UNLEASH_APP_NAME"),
};

const ToggleProvider = ({
  children,
  userId,
}: React.PropsWithChildren<{ userId: string }>) => {
  if (
    getEnvironmentVariable("APP_MODE") === "nightly" ||
    getEnvironmentVariable("APP_MODE") === "local"
  ) {
    return (
      <FlagProvider config={{ ...config, context: { userId } }}>
        {children}
      </FlagProvider>
    );
  }
  return <>{children}</>;
};

export { ToggleProvider };

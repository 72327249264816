import FreeSvgUrl from "@icons/free.svg?url";
import SubscriptionSvgUrl from "@icons/subscription.svg?url";
import { Trans } from "@lingui/react/macro";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import partition from "lodash-es/partition";

import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";
import { getLocalizedPeriods } from "@/common/utils/period";
import {
  getCurrentCompany,
  PlanPeriodToType,
  PlanToType,
} from "@/generated/api/users";
import { FullscreenModalLayout } from "@/routes/layout/layout.fullscreen";

import { Payments } from "./payments";

const Overview = () => {
  const subscriptions = useSubscriptions();

  return (
    <>
      <FullscreenModalLayout.Title>
        <Trans>Předplatné</Trans>
      </FullscreenModalLayout.Title>
      <div className="flex flex-col gap-y-4">
        {!subscriptions ? (
          <Subscription
            id={null}
            period={null}
            plan={{ type: "BASIC", description: description.BASIC }}
          />
        ) : (
          <>
            {subscriptions.paid.map((s, i) => (
              <Subscription key={"subscription" + i.toString()} {...s} />
            ))}
            {subscriptions.manual.map((s, i) => (
              <ManualSubscription
                key={"manual-subscription" + i.toString()}
                {...s}
              />
            ))}
            <Payments />
          </>
        )}
      </div>
    </>
  );
};

const Subscription = ({
  id,
  period,
  plan,
}: {
  id: number | null;
  period: string | null;
  plan: { type: string; description: React.ReactNode };
}) => {
  const sidebar = useSidebar();

  return (
    <button
      className="flex gap-4 rounded-2xl p-4 shadow-can-light-box"
      onClick={() =>
        sidebar.actions.openSubscription({
          subscriptionId: id,
        })
      }
    >
      <span className="flex h-[56px] w-[56px] shrink-0 items-center justify-center rounded-full bg-can-silver-gray">
        <img className="h-[24px] w-[24px]" src={SubscriptionSvgUrl} />
      </span>
      <span className="text-left">
        <strong className="text-can-midnight-steel">
          {plan.type}
          {period ? " - " + period : null}
        </strong>
        <p className="text-xs">{plan.description}</p>
      </span>
    </button>
  );
};

const ManualSubscription = ({ id }: { id: number }) => {
  const sidebar = useSidebar();

  return (
    <div
      role="button"
      className="flex gap-4 rounded-2xl p-4 shadow-can-light-box"
      onClick={() => sidebar.actions.openSubscription({ subscriptionId: id })}
    >
      <span className="flex h-[56px] w-[56px] items-center justify-center rounded-full bg-can-silver-gray">
        <img className="h-[24px] w-[24px]" src={FreeSvgUrl} />
      </span>
      <div>
        <strong className="text-can-midnight-steel">
          <Trans>Zkušební předplatné zdarma</Trans>
        </strong>
        <p className="text-xs">
          <Trans>
            Užijte si na omezenou dobu rozšířenou verzi aplikace zcela zdarma.
          </Trans>
        </p>
      </div>
    </div>
  );
};

const useSubscriptions = () => {
  const company = useSuspenseQuery(currentCompanyQuery());
  const [manualSubscriptions, paidSubscriptions] = partition(
    company.data.subscriptions,
    (s) => s.source === "MANUAL",
  );

  const mapSubscription = (s: {
    id: number;
    period?: {
      type: PlanPeriodToType;
    };
    plan: { type: PlanToType };
  }) => ({
    id: s.id,
    plan: {
      type: s.plan.type,
      description: description[s.plan.type],
    },
    period: s.period
      ? getLocalizedPeriods()[s.period.type].toLowerCase()
      : null,
  });

  if (manualSubscriptions.length === 0 && paidSubscriptions.length === 0) {
    // BASIC plan doesn't have any subscription by default
    return;
  }

  return {
    paid: paidSubscriptions.map(mapSubscription),
    manual: manualSubscriptions.map(mapSubscription),
  };
};

const currentCompanyQuery = () =>
  queryOptions({
    queryKey: ["company", "current"],
    queryFn: ({ signal }) => getCurrentCompany({ signal }),
  });

const description: Record<PlanToType, React.ReactNode> = {
  BASIC: (
    <Trans>
      Některé funkce jsou pro vás nedostupné. Odemkněte si všechny funkce ještě
      dnes!
    </Trans>
  ),
  PLUS: (
    <Trans>
      Některé funkce jsou pro vás nedostupné. Odemkněte si všechny funkce ještě
      dnes!
    </Trans>
  ),
  PREMIUM: (
    <Trans>
      Skvěle! Máte k dispozici všechny funkce, které vám v Clever˚Analytics'
      nabízíme.
    </Trans>
  ),
};

export { Overview };

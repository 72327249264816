import { queryOptions, useQuery } from "@tanstack/react-query";
import React from "react";
import invariant from "tiny-invariant";
import { z } from "zod";

import { getCompanyFeatures, getDefaultFeatures } from "@/generated/api/users";

import { Failed } from "../components/info/info";
import { planTypeSchema } from "../services/user";

type PlanFeaturesSchema = z.infer<typeof planFeaturesSchema>;

type Props = React.PropsWithChildren<{
  company: unknown;
}>;

const planFeaturesSchema = z.record(
  z.union([
    z.literal("MARKET_NEWS"),
    z.literal("QUICK_TRADING"),
    z.literal("STOCK_EXCHANGE"),
    z.literal("PURCHASE_PRICES"),
    z.literal("STORAGES"),
    z.literal("TRADING"),
    z.literal("TRANSACTIONS_ORDERS"),
  ]),
  z.object({
    menu: z.boolean(),
    mode: z.union([z.literal("FULL"), z.literal("TRIAL")]),
    planType: planTypeSchema,
    planId: z
      .number()
      .nullish()
      .transform((v) => v ?? null),
  }),
);

const Context = React.createContext<PlanFeaturesSchema | undefined>(undefined);

const FeatureProvider = ({ children, company }: Props) => {
  const companyFeatures = useQuery({
    ...companyFeaturesQuery(),
    enabled: !!company,
  });
  const defaultFeatures = useQuery({
    ...defaultFeaturesQuery(),
    enabled: !company,
  });

  const errors = [companyFeatures.error, defaultFeatures.error];
  const pending =
    (companyFeatures.isPending && !!company) ||
    (defaultFeatures.isPending && !company);
  const features = companyFeatures.data ?? defaultFeatures.data;

  if (errors.every(Boolean)) {
    return <Failed error={errors.find(Boolean)} />;
  }

  if (pending) {
    return null;
  }

  const safeFeatures = planFeaturesSchema.parse(features);

  return <Context.Provider value={safeFeatures}>{children}</Context.Provider>;
};

const useFeatureProvider = () => {
  const ctx = React.useContext(Context);
  invariant(
    ctx,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "useFeatureProvider only available inside <FeatureProvider /> component",
  );
  return ctx;
};

const companyFeaturesQuery = () =>
  queryOptions({
    queryKey: ["features"],
    queryFn: ({ signal }) => getCompanyFeatures({ signal }),
    staleTime: Infinity,
  });

const defaultFeaturesQuery = () =>
  queryOptions({
    queryKey: ["default-features"],
    queryFn: ({ signal }) => getDefaultFeatures({ signal }),
    staleTime: Infinity,
  });

type Features = z.infer<typeof planFeaturesSchema>;

export { FeatureProvider, useFeatureProvider };
export type { Features };

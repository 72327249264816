import { useAuth0 } from "@auth0/auth0-react";
import CircleUserSvg from "@icons/circle-user.svg?react";
import CrossSvg from "@icons/cross.svg?react";
import MenuBurgerSvg from "@icons/menu-burger.svg?react";
import { t } from "@lingui/core/macro";
import { useFlag } from "@unleash/proxy-client-react";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { useFirstCompany } from "@/common/hooks/use-first-company";
import { useLazyScrollbarToggle } from "@/common/hooks/use-scrollbar-toggle";
import { useToggle } from "@/common/hooks/use-toggle";
import { useFeatureProvider } from "@/common/providers/feature-provider";
import {
  Screen,
  useSidebar,
} from "@/common/providers/sidebar-provider/sidebar-provider";

import { Logo } from "../logo/logo";
import { getClassName, NavBar } from "../nav-bar/nav-bar";
import { NavBar as OldNavBar } from "../nav-bar/nav-bar.old";
import { NotificationBell } from "../push-notifications/push-notifications";
import { UserMenu } from "../user/user-menu/user-menu";

const TopBar = () => {
  const toggleBodyScrollbar = useLazyScrollbarToggle();
  const sidebar = useSidebar();
  const { user } = useAuth0();
  const expanded = useToggle();
  const expandedProfile = useToggle();
  const { pathname } = useLocation();
  const company = useFirstCompany();
  const close = expanded.setOff;
  const hasPackages = useFlag("packages");
  const features = useFeatureProvider();

  React.useEffect(() => {
    close();
  }, [pathname, close]);

  const handleToggle = () => {
    expanded.toggle();
    toggleBodyScrollbar(!expanded.on);
  };

  const handleToggleProfile = () => {
    expandedProfile.toggle();
  };

  const handleMenuAction = (screen: Screen) => () => {
    expandedProfile.toggle();
    expanded.toggle();
    sidebar.actions.openScreen(screen);
  };

  return (
    <div className="can-top-bar fixed z-[1] flex w-full justify-between bg-white sm:hidden">
      {expanded.on ? null : <Logo className="ml-6 md:hidden" />}
      <div
        className={`flex basis-full flex-wrap items-center justify-between gap-6 ${expanded.on ? "mt-5" : ""}`}
      >
        {expanded.on ? (
          <>
            <button
              data-testid="user-profile"
              onClick={handleToggleProfile}
              className="ml-6 flex gap-2 font-bold text-can-forest-teal"
            >
              <CircleUserSvg /> {user?.name}
            </button>
            <button
              data-testid="mobile-menu-toggle"
              aria-label={t`Zavřít`}
              onClick={handleToggle}
              className="mr-6"
            >
              <CrossSvg />
            </button>
            {expandedProfile.on ? (
              <nav className="z-10 basis-full bg-white px-6 font-bold text-can-forest-teal">
                <UserMenu.Mobile>
                  <UserMenu
                    renderItem={{
                      profile: (item) => (
                        <button
                          onClick={handleMenuAction("user-profile")}
                          className="flex w-full gap-4"
                        >
                          {item.icon}
                          {item.label}
                        </button>
                      ),
                      subscription: (item) => (
                        <button
                          onClick={handleMenuAction("subscriptions")}
                          className="flex w-full gap-4"
                        >
                          {item.icon}
                          {item.label}
                        </button>
                      ),
                    }}
                  />
                </UserMenu.Mobile>
              </nav>
            ) : null}
          </>
        ) : (
          <div className="ml-auto mt-4">
            <NotificationBell />
            <button
              data-testid="mobile-menu-toggle"
              onClick={handleToggle}
              className="ml-6"
            >
              <MenuBurgerSvg className="mr-6 h-6 w-6" />
            </button>
          </div>
        )}
        {expanded.on ? (
          <aside className="z-[1] flex h-[100dvh] w-full flex-col bg-can-forest-teal pt-6">
            {hasPackages ? (
              <NavBar
                plan={{
                  features,
                  planId:
                    company?.plan.planId ?? Object.values(features)[0].planId,
                  type: company?.plan.type ?? "BASIC",
                }}
                renderItem={(link, Wrapper) => (
                  <NavLink
                    viewTransition
                    key={link.to}
                    className={({ isActive }) =>
                      `!justify-start gap-4 px-6 ${getClassName({ isActive })}`
                    }
                    to={link.to}
                  >
                    <Wrapper>{link.icon}</Wrapper>
                    {link.label}
                  </NavLink>
                )}
              />
            ) : (
              <OldNavBar
                renderItem={(link) => (
                  <NavLink
                    key={link.to}
                    className={({ isActive }) =>
                      `!justify-start gap-4 px-6 ${getClassName({ isActive })}`
                    }
                    to={link.to}
                  >
                    {link.icon}
                    {link.label}
                  </NavLink>
                )}
              />
            )}
          </aside>
        ) : null}
      </div>
    </div>
  );
};

export { TopBar };

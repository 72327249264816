import BankTransferSvgUrl from "@icons/bank-transfer.svg?url";
import SubscriptionSvgUrl from "@icons/subscription.svg?url";
import { Trans } from "@lingui/react/macro";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";
import { currencyFormatter, getPrice } from "@/common/services/formatter";
import { getLocalizedPaymentStatus } from "@/common/utils/payment-status";
import { getLocalizedPeriods } from "@/common/utils/period";
import {
  getCompanyPayments,
  PaymentToStatus,
  PaymentToType,
  PlanPeriodToType,
} from "@/generated/api/users";

import { Failed } from "../info/info";
import { Pagination } from "../pagination/pagination";
import { Spinner } from "../spinner/spinner";

const Payments = () => {
  const [page, setPage] = React.useState(1);
  const payments = useSuspenseQuery(paymentsQuery({ page: page.toString() }));

  return (
    <>
      <ul>
        {payments.data.data.map((payment) => (
          <Payment
            type={payment.type}
            amount={payment.amount}
            date={DateTime.fromISO(payment.createdAt)}
            key={payment.id}
            id={payment.id}
            period={payment.subscription.planPeriod}
            planType={payment.subscription.planType}
            status={payment.status}
          />
        ))}
      </ul>
      <Pagination
        pageSize={5}
        total={payments.data.totalSize}
        getter={() => page}
        setter={setPage}
      />
    </>
  );
};

const Payment = ({
  amount,
  date,
  id,
  period,
  planType,
  type,
  status,
}: {
  amount: string;
  date: DateTime;
  id: number;
  period?: PlanPeriodToType;
  planType: string;
  status: PaymentToStatus;
  type: PaymentToType;
}) => {
  const { actions } = useSidebar();

  return (
    <li>
      <button
        onClick={() => actions.openPaymentDetail({ paymentId: id })}
        className="flex w-full flex-col gap-y-2 rounded-2xl p-4 text-can-midnight-steel shadow-can-light-box focus:shadow-can-light-box"
      >
        <span className="flex w-full items-center gap-x-2">
          <img
            src={
              type === "BANK_TRANSFER" ? BankTransferSvgUrl : SubscriptionSvgUrl
            }
          />
          <strong>{amount}</strong>
          <Status status={status} />
        </span>
        <span className="text-xs font-semibold">
          {planType} {period ? `- ${getLocalizedPeriods()[period]}` : null}
        </span>
        <span className="text-xs text-can-slate-blue-gray">
          {date.toLocaleString(DateTime.DATETIME_SHORT)}
        </span>
      </button>
    </li>
  );
};

const Status = ({ status }: { status: PaymentToStatus }) => {
  let item: { gradient: string; text: React.ReactNode } = {
    text: getLocalizedPaymentStatus()[status],
  } as typeof item;

  switch (status) {
    case "ERROR": {
      item = {
        ...item,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        gradient: "from-[#F99B87] to-[#E55743]",
      };
      break;
    }
    case "WAITING": {
      item = {
        ...item,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        gradient: "from-can-tranquil-azure to-can-mystic-aqua",
      };
      break;
    }
    case "REFUNDED": {
      item = {
        ...item,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        gradient: "from-[#84D4A3] to-[#51B678]",
      };
      break;
    }
    case "COMPLETED": {
      item = {
        ...item,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        gradient: "from-[#84D4A3] to-[#51B678]",
      };
      break;
    }
    case "CANCELLED": {
      item = {
        ...item,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        gradient: "from-[#FFC684] to-[#FF914D]",
      };
      break;
    }
    case "INITIALIZED": {
      item = {
        ...item,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        gradient: "from-[#FFC684] to-[#FF914D]",
      };
      break;
    }
    default: {
      const exhaustiveCheck: never = status;
      // eslint-disable-next-line lingui/no-unlocalized-strings
      throw new Error(`Unhandled status case: ${exhaustiveCheck}`);
    }
  }

  return (
    <small
      className={`ml-auto rounded bg-can-gradient-to-r-80 ${item.gradient} p-1.5 text-right text-xs font-normal text-white`}
    >
      {item.text}
    </small>
  );
};

const select = (data: Awaited<ReturnType<typeof getCompanyPayments>>) => {
  return {
    ...data,
    data: data.data.map(({ currency, ...payment }) => ({
      ...payment,
      amount: currencyFormatter(currency.type, {
        maximumFractionDigits: 0,
      }).format(getPrice(payment.amount, currency.precision)),
    })),
  };
};
const paymentsQuery = ({
  page,
}: Pick<
  Exclude<Parameters<typeof getCompanyPayments>[0], undefined>,
  "page"
>) =>
  queryOptions({
    queryKey: ["payments", page],
    queryFn: ({ signal }) =>
      getCompanyPayments({ sortDir: "DESC", size: "5", page }, { signal }),
    select,
  });

const PaymentsTree = () => (
  <ErrorBoundary fallbackRender={({ error }) => <Failed error={error} />}>
    <React.Suspense fallback={<Spinner />}>
      <h2 className="font-bold text-can-forest-teal">
        <Trans>Platby a faktury</Trans>
      </h2>
      <Payments />
    </React.Suspense>
  </ErrorBoundary>
);

export { PaymentsTree as Payments };

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

import { logger } from "@/common/services/logger";
import { getUserState } from "@/common/services/user";

import { useTokenUpdate } from "../providers/token-provider";

/**
 * Whenever the access token structure is changed, we might want to get a fresh token before
 * actually telling the user is incomplete.
 */
const useIncompleteUserRefresh = (params: ReturnType<typeof useAuth0>) => {
  const [, updateToken] = useTokenUpdate();
  const [retries, setRetries] = React.useState(1);

  React.useEffect(() => {
    if (retries === 0) {
      return;
    }

    if (!params.user) {
      return;
    }

    const incomplete = getUserState(params.user) === "incomplete";

    if (!incomplete) {
      setRetries(0);
      return;
    }

    updateToken?.()
      .catch((e) => {
        logger.error(e);
      })
      .finally(() => {
        setRetries((c) => {
          return c - 1;
        });
      });
  }, [params.user, retries, updateToken]);

  return params;
};

export { useIncompleteUserRefresh };

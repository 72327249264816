import { ErrorMessage } from "@hookform/error-message";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useFormContext } from "react-hook-form";

import { combineCallingCodeWithCountry } from "@/common/utils/phone";
import { countryCodeToFlag } from "@/common/utils/unicode";

const PhoneNumber = ({
  name,
  callingCodes,
}: {
  name: string;
  callingCodes: { id: string; phoneCountryCode: string }[];
}) => {
  const form = useFormContext();
  return (
    <label className="flex flex-col" htmlFor="phoneNumber">
      <Trans>Telefonní číslo</Trans>
      <div className="group flex gap-2">
        <div className="flex flex-col gap-2 ">
          <select
            {...form.register(`${name}.countryCode`, {
              required: t`Vyplňte prosím telefonní předvolbu`,
            })}
            className="mt-2 h-[calc(theme(spacing.14)+2px)] w-[130px] rounded-lg font-bold group-has-[[role=alert]]:border-can-russet"
          >
            <option disabled value="">
              <Trans>Předvolba</Trans>
            </option>
            {callingCodes.map((country) => (
              <option
                value={combineCallingCodeWithCountry(country)}
                key={combineCallingCodeWithCountry(country)}
              >
                {countryCodeToFlag(country.id)} {country.phoneCountryCode}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={form.formState.errors}
            name={`${name}.countryCode`}
            render={({ message }) => (
              <p
                role="alert"
                className="basis-full text-left text-xs text-can-russet"
              >
                {message}
              </p>
            )}
          />
        </div>

        <div className="flex flex-grow flex-col gap-2 self-start">
          <input
            {...form.register(`${name}.phoneNumber`, {
              required: t`Vyplňte prosím telefonní číslo`,
              pattern: {
                value: /\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d\s*\d/,
                message: t`Telefonní číslo by mělo mít devět číslic`,
              },
            })}
            placeholder={t`Vložte telefonní číslo`}
            type="text"
            autoComplete="off"
            className="mt-2 w-full flex-1 rounded-lg border p-4 font-bold group-has-[[role=alert]]:border-can-russet"
            id="phoneNumber"
          />
          <ErrorMessage
            errors={form.formState.errors}
            name={`${name}.phoneNumber`}
            render={({ message }) => (
              <p
                role="alert"
                className="basis-full text-left text-xs text-can-russet"
              >
                {message}
              </p>
            )}
          />
        </div>
      </div>
    </label>
  );
};

export { PhoneNumber };

import { Trans } from "@lingui/react/macro";

import { useDownloadInvoiceMutation } from "@/routes/shared/api/get-invoice";

const Invoice = ({ id }: { id: number }) => {
  const downloadInvoice = useDownloadInvoiceMutation();
  const handleDownloadInvoice = () => {
    downloadInvoice.mutate(id);
  };
  return (
    <button className="text-can-forest-teal" onClick={handleDownloadInvoice}>
      <Trans>Stáhnout</Trans>
    </button>
  );
};

export { Invoice };

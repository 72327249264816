import { Link } from "react-router-dom";

import LogoSvg from "../../../assets/logo.svg";

const Logo = ({ className }: { className?: string }) => {
  return (
    <Link className={`flex-shrink-0 ${className}`} to="/">
      <img src={LogoSvg} className={`h-[72px] w-[72px] bg-white`} />
    </Link>
  );
};

export { Logo };
